import React from 'react'
import Shimmer from "react-shimmer-effect"
import './Shimmer.css'

export const ShimmerImage = (props) => {
	var size = props.size === undefined ? "sm" : props.size
	return (
		<Shimmer>
			<div className={`image-size-${size}`}></div>
		</Shimmer>
	);
}

export const ShimmerText = (props) => {
	var size = props.size === undefined ? "full" : props.size
	return (
		<Shimmer>
			<div className={`shimmer-data-${size}`}></div>
		</Shimmer>
	);
}