export const apiURL = "https://api.vunime.my.id/web/anime/"
export const apiGrab = "https://api.vunime.my.id/web/grab/"
export const urlImgLoc = "https://api.vunime.my.id/assets/images/"
export const jwtKey = "4Lf@t1h.R@r@"
let usertoken = localStorage.usertoken;

export const nullIsEmpty = (value) => {
	let data;
	data = value === null || value === '' ? '' : value
	return data
}

export const emptyIsNull = (value) => {
	let data;
	data = value === null || value === '' ? null : value
	return data
}

export const headersAPI = {
	headers: {
		'Content-Type': 'application/json;charset=UTF-8'
	}
}