import React from 'react';

const Logo = (props) => {
	return (
		<span className="brand-link">
			<img src="/assets/images/tools/logo-light.png" alt="Vunime Logo" className="brand-image"/>
			<span className="brand-text font-weight-light">&nbsp;</span>
		</span>
	);
}

export default Logo