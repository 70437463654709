import React, { Fragment } from 'react'
import Shimmer from "react-shimmer-effect";
import './shimmerload.css'

const ShimmerTable = () => {
	return (
		<tr>
			<td>
			<div className="area-image">
				<Shimmer>
					<div className="image-size"></div>
				</Shimmer>
			</div>
				<Shimmer>
					<div className="text-title"></div>
				</Shimmer>
			</td>
			<td>
				<Shimmer>
					<div className="text-global"></div>
				</Shimmer>
			</td>
			<td>
				<Shimmer>
					<div className="text-global"></div>
				</Shimmer>
			</td>
			<td>
				<Shimmer>
					<div className="text-global"></div>
				</Shimmer>
			</td>
			<td>
				<Shimmer>
					<div className="text-global"></div>
				</Shimmer>
			</td>
			<td>
				<Shimmer>
					<div className="text-global"></div>
				</Shimmer>
			</td>
			<td>
				<Shimmer>
					<div className="text-global"></div>
				</Shimmer>
			</td>
		</tr>
	)
}

const ShimmerLoad = () => {
	return (
		<Fragment>
			<ShimmerTable />
			<ShimmerTable />
		</Fragment>
	)
}

export default ShimmerLoad