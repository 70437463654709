import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Footer } from "./Components"

const Main = props => {
	document.body.classList.add('layout-top-nav');
	document.body.classList.add('elevation-1');
	document.body.classList.remove('sidebar-mini');
	const { children } = props;
	return (
		<div className="wrapper">
			<Navbar />
			{children}
			<Footer />
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node
};

export default Main;