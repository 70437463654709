import React, { Component, Fragment } from "react"
import axios from "axios"
import jwt from 'jsonwebtoken'
import { Helmet } from 'react-helmet';
import { connect } from "react-redux"
import Swal from 'sweetalert2'
import Pagination from '@material-ui/lab/Pagination'
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { TitleContent, Breadcrumbs } from '../component'
import { apiURL, jwtKey } from "../../../variable/Global"
import { RowTable, ShimmerLoad, FormReply } from "./Components"

class Message extends Component {
	constructor(props){
		super(props)
		this.props.setProgressBar('OPEN')
		this.state = {
			data: [],
			pathname: "",
			showModal: false,
			dataMessage: null,
			page: 1
		}
	}

	getData = () => {
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		let postData = new FormData()
		postData.append('page', page)
		axios.post(apiURL +"message", postData)
			.then(res => {
				let data;
				if(res.data.result.toString() === "1"){
					data = res.data.data
				} else {
					data = null
				}
				this.setState({
					data: data
				})
				this.props.setProgressBar('')
			})
	}

	handlePage = (event, value) => {
		if(this.state.paging.toString() !== value.toString()){
			this.props.setProgressBar('OPEN')
			this.setState({
				page: value,
				data: [],
			})
			if(value === 1){
				this.props.history.replace(`/alyka-admin/info/dialog`)
			} else {
				this.props.history.replace(`/alyka-admin/info/dialog/${value}`)
			}
		}
	}

	handleChangeData = (newData) => {
		this.setState({
			data: newData,
			showModal: false
		})
	}

	handleReply = (value) => {
		this.setState({
			showModal: true,
			dataMessage: value
		})
	}

	handleDelete = (id) => {
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		let enid = jwt.sign(id, jwtKey)
		let postData = new FormData()
		postData.append('page', page)
		postData.append('id', enid)
		Swal.queue([{
			title: "Apa Anda yakin?",
			text: "Data yang di hapus tidak bisa di kembalian",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: '#dc3545',
			confirmButtonText: 'Ya, Hapus',
			cancelButtonText: 'Batal',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				axios.post(apiURL +"delete-version", postData)
					.then(res => {
						Swal.fire(
							'Berhasil!',
							'Data berhasil di hapus.',
							'success'
						)
						let data = res.data.data
						this.setState({
							data: data
						})
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => Swal.isLoading(),
		}]);
	}

	handleShowModal = () => {
		this.setState({
			showModal: true,
			dataMessage: null
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false,
			dataMessage: null
		})
	}

	componentDidMount()
	{
		if(window.location.href !== this.state.pathname)
		{
			let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
			this.setState({
				pathname: window.location.href,
				page: page
			})
			this.getData()
		}
	}

	componentDidUpdate()
	{
		if(window.location.href !== this.state.pathname)
		{
			let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
			this.setState({
				pathname: window.location.href,
				page: page
			})
			this.getData()
		}
	}

	/* Komponen data */
	contentTable = () => {
		if(this.state.data === null){
			return (
				<Fragment>
					<tr>
						<td colSpan={6} className="text-center"><i>Belum ada data</i></td>
					</tr>
				</Fragment>
			)
		} else if(this.state.data.length < 1){
			return (
				<Fragment>
					<ShimmerLoad />
					<ShimmerLoad />
				</Fragment>
			)
		} else {
			return (
				<Fragment>
					{
						this.state.data.map((value, i) => {
							return <RowTable
								key={value.id}
								fullname={value.fullname}
								nickname={value.nickname}
								avatar={value.avatar}
								email={value.email}
								desc={value.deskripsi}
								status={value.status_read}
								createDate={value.create_date}
								handleDelete={() => this.handleDelete(value.id)}
								handleReply={() => this.handleReply(value)}
							/>
						})
					}
				</Fragment>
			)
		}
	}

	pagingShow = () => {
		if(this.state.anime === null){
			return <Fragment></Fragment>
		} else {
			return (
				<Fragment>
					<Pagination
						count={this.state.totalPage}
						variant="outlined"
						shape="rounded"
						color="primary"
						page={this.state.page}
						onChange={this.handlePage}
						showFirstButton
						showLastButton
					/>
				</Fragment>
			)
		}
	}

	render(){
		return (
			<Fragment>
				<Helmet>
					<title>Message</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="far fa-comments" title="Masukan / Message" />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Versi Aplikasi" path="/alyka-admin/info/version" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">Masukan / Message</h3>
										</div>
										<div className="card-body">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<th>Pengguna</th>
														<th>Deskripsi</th>
														<th className="text-center" style={{width: '125px'}}>Tanggal</th>
														<th className="text-center" style={{width: '135px'}}>Action</th>
													</tr>
												</thead>
												<tbody>
													{this.contentTable()}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<FormReply
					showModal={this.state.showModal}
					dataMessage={this.state.dataMessage}
					onClose={this.handleCloseModal}
					page={this.state.page}
					handleChangeData={(newData) => this.handleChangeData(newData)}
				/>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(Message)