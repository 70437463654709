import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import axios from 'axios'
import jwt from 'jsonwebtoken'
import Swal from 'sweetalert2'
import Pagination from '@material-ui/lab/Pagination'
import { setProgressBar } from '../../../redux/Actions/ProgressAction';
import { setCountData } from '../../../redux/Actions/CountData';
import { TitleContent, Breadcrumbs } from '../component';
import { ShimmerLoad, RawTable, DetailAnimeBroken } from './Components'
import { apiURL, jwtKey } from '../../../variable/Global';

class BrokenLink extends Component
{
	constructor(props){
		super(props)
		this.props.setProgressBar('OPEN');
		this.state = {
			data: [],
			totalPage: 1,
			page: 1,
			pathname: '',
			showDetail: false,
			detailId: null
		}
	}

	getData = (page) => {
		let postData = new FormData();
		postData.append('page', page)
		axios.post(apiURL+"broken-link", postData)
			.then(res => {
				let data = res.data.data
				this.setState({ 
					data: data, 
					totalPage: res.data.totalPage,
					page: parseInt(page),
				})
				this.props.setProgressBar('');
			})
	}

	handleProcess = (id, title) => {
		let enId = jwt.sign(id, jwtKey)
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
		let postData = new FormData()
		postData.append('id', enId)
		postData.append('page', page)
		Swal.queue([{
			title: "Apakah \""+ title +"\" sudah di proses?",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#171717",
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, Proses',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return axios.post(apiURL +"broken-process", postData)
					.then(res => {
						let data = res.data.data
						this.setState({ 
							data: data, 
							totalPage: res.data.totalPage,
							page: parseInt(page),
						})
						this.props.setCountBroken(data[0].total_all)
						this.props.setProgressBar('');
						Swal.fire(
							'Berhasil!',
							'Anime broken link berhasil di proses.',
							'success'
						)
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}]);
	}

	handlePage = (event, value) => {
		if(this.state.page.toString() !== value.toString()){
			this.props.setProgressBar('OPEN');
			this.setState({
				page: value,
				data: [],
			})
			if(value === 1){
				this.props.history.replace(`/alyka-admin/broken`)
			} else {
				this.props.history.replace(`/alyka-admin/broken/${value}`)
			}
		}
	}

	showDetailAnimeBroken = (tampil, detailId) => {
		this.setState({ showDetail: tampil, detailId: detailId })
	}

	componentDidMount(){
		this.getData("1")
	}

	componentDidUpdate(){
		if(this.props.history.location.pathname !== this.state.pathname){
			this.setState({
				pathname: this.props.history.location.pathname
			})
			let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
			this.getData(page);
			window.scrollTo(0, 0)
		}
	}

	//************************************************************************************************************ */
	//											Fungsi Komponen
	//************************************************************************************************************ */

	contentTable = () => {
		if(this.state.data === null){
			return (
				<Fragment>
					<tr>
						<td colSpan={5} className="text-center"><i>Belum ada data</i></td>
					</tr>
				</Fragment>
			)
		} else if(this.state.data.length < 1){
			return (
				<Fragment>
					<ShimmerLoad />
					<ShimmerLoad />
				</Fragment>
			)
		} else {
			return (
				<Fragment>
					{
						this.state.data.map((value, i) => {
							return <RawTable
								key={value.id}
								title={value.title}
								imageCover={value.image_cover}
								episode={value.episode}
								createDate={value.create_time}
								handleProcess={() => this.handleProcess(value.id, value.title)}
								showDetailBroken={() => this.showDetailAnimeBroken(true, value.id)}
							/>
						})
					}
				</Fragment>
			)
		}
	}

	pagingShow = () => {
		if(this.state.totalPage > 1){
			return (
				<div className="card-footer">
					<Pagination
						count={this.state.totalPage}
						variant="outlined"
						shape="rounded"
						color="primary"
						page={this.state.page}
						onChange={this.handlePage}
						showFirstButton
						showLastButton
					/>
				</div>
			)
		} else {
			return <Fragment></Fragment>
		}
	}

	modalShow = () => {
		return <Fragment>
			<DetailAnimeBroken
				showModal={this.state.showDetail}
				handleClose={ (t) => this.showDetailAnimeBroken(false, null) }
				detailId={this.state.detailId}
			/>
		</Fragment>
	}

	render()
	{
		return (
			<Fragment>
				<Helmet>
					<title>Broken Link</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="far fa-list-alt" title="Broken Link" />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Broken Link" path="/alyka-admin/info/notice" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">Broken Link</h3>
										</div>
										<div className="card-body">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<th>Judul</th>
														<th className="text-center" style={{width: '125px'}}>Tanggal</th>
														<th className="text-center" style={{width: '135px'}}>Action</th>
													</tr>
												</thead>
												<tbody>
													{this.contentTable()}
												</tbody>
											</table>
										</div>
										<div className="card-footer">
											{this.pagingShow()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				{this.modalShow()}
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	},
	setCountBroken: count => {
		dispatch(setCountData(count))
	}
});

export default connect(null, mapDispatchToProps)(BrokenLink)