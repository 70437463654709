import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { setProgressBar } from "../../../redux/Actions/ProgressAction"

class Navbar extends Component{
	constructor(props){
		super(props)
		this.props.setProgressBar('OPEN')
	}

	render(){
		return (
			<nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
				<div className="container">
					<Link to="/" className="navbar-brand">
						<img src="/assets/images/tools/logo.png" alt="AdminLTE Logo" className="brand-image" style={{opacity: '.8', height: '40px'}} />
					</Link>
	
					<div className="collapse navbar-collapse order-3" id="navbarCollapse">
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link to="/" className="nav-link">Home</Link>
							</li>
							<li className="nav-item">
								<Link to="/term-condition" className="nav-link">Term & Condition</Link>
							</li>
							<li className="nav-item">
								<Link to="/privacy-policy" className="nav-link">Privacy Policy</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(withRouter(Navbar));