import React, { Component, Fragment } from 'react';
import { Form } from 'react-bootstrap'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import { InputTemplate } from '../../../../variable/TextInput';
import { nullIsEmpty, apiURL, urlImgLoc, jwtKey } from '../../../../variable/Global';

const formValid = (formErrors, formInfo) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formInfo).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

class FormDialog extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			showModal: false,
			dataTypeInfo: ['Infomation', 'Maintenance'],
			dataPriority: ['Low', 'High'],
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
			formInfo: {
				id: "",
				typeInfo: "",
				priority: "",
				title: "",
				image: "",
				url: "",
				desc: ""
			},
			formErrors: {
				typeInfo: "",
				priority: "",
				title: "",
				image: "",
				url: "",
				desc: ""
			}
		}
	}

	handleSelect = (e) => {
		e.preventDefault()
		let formErrors = { ...this.state.formErrors };
		let formInfo = { ...this.state.formInfo };
		const {name, value} = e.target;
		formErrors[name] = ""
		formInfo[name] = value
		this.setState({ formInfo, formErrors })
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formInfo = { ...this.state.formInfo };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formInfo[name] = value === "" ? null : value
	
		this.setState({ formErrors, formInfo });
	}

	handleChangeNoValidate = (e) => {
		e.preventDefault()
		const { name, value } = e.target
		let formInfo = { ...this.state.formInfo }
		formInfo[name] = value === "" ? null : value
		this.setState({ formInfo })
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		return formErrors[name]
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let { formErrors, formInfo } = this.state
		formErrors.typeInfo = this.validate('typeInfo', formInfo.typeInfo)
		formErrors.priority = this.validate('priority', formInfo.priority)
		formErrors.title = this.validate('title', formInfo.title)
		formErrors.desc = this.validate('desc', formInfo.desc)
		this.setState({ formErrors })
		if(formValid(formErrors, formInfo)){
			this.setState({
				nameBtnSave: ButtonName(1),
				btnSaveDisabled: true,
			})
			let postData = new FormData()
			postData.append('title', formInfo.title)
			postData.append('type', formInfo.typeInfo)
			postData.append('priority', formInfo.priority)
			postData.append('desc', formInfo.desc)
			if(formInfo.image !== ""){
				postData.append('image', formInfo.image)
			}
			if(formInfo.url !== ""){
				postData.append('url', formInfo.url)
			}
			if(formInfo.id !== ""){
				let enid = jwt.sign(formInfo.id, jwtKey)
				postData.append('id', enid)
			}
			axios.post(apiURL +"save-dialog-info", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleChangeData(res.data.data)
					}
				})
		}
	}

	handleChangeData = (newData) => {
		this.props.handleChangeData(newData)
	}

	readImage = (e) => {
		e.preventDefault()
		let { files } = e.target
		let formInfo = { ...this.state.formInfo }
		if(files !== undefined){
			var reader = new FileReader()
			reader.readAsDataURL(files[0])
			reader.onload = (event) => {
				formInfo.image = event.target.result
				this.setState({ formInfo })
			}
		}
	}

	dataEdit = () => {
		let formInfo = { ...this.state.formInfo }
		let data = this.props.dataEdit
		formInfo.typeInfo = data.tipe
		formInfo.priority = data.priority
		formInfo.title = data.judul
		formInfo.image = data.images === null ? nullIsEmpty(data.images) : urlImgLoc +"dialog_info/"+ data.images
		formInfo.url = nullIsEmpty(data.url)
		formInfo.desc = data.content
		formInfo.id = data.id
		this.setState({ formInfo })
	}

	componentDidUpdate()
	{
		if(this.state.showModal !== this.props.showModal){
			this.setState({
				showModal: this.props.showModal
			});
			if(!this.props.showModal){
				this.setState({
					nameBtnSave: ButtonName(0),
					btnSaveDisabled: false,
					formInfo: {
						id: "",
						typeInfo: "",
						priority: "",
						title: "",
						image: "",
						url: "",
						desc: ""
					},
					formErrors: {
						typeInfo: "",
						priority: "",
						title: "",
						image: "",
						url: "",
						desc: ""
					}
				})
			}
			
			if(this.props.dataEdit !== null){
				this.dataEdit()
			}
		}
	}

	render()
	{
		const { formErrors, formInfo } = this.state;
		return (
			<Modal show={this.state.showModal} onHide={this.props.onClose} size="md" backdrop="static">
				<Form noValidate>
					<Modal.Header closeButton>
						<Modal.Title><i className="fas fa-edit"></i> Information</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-12">
								<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
									<InputLabel id="demo-simple-select-outlined-label">Tipe *</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										onChange={this.handleSelect}
										label="Tipe *"
										name="typeInfo"
										value={formInfo.typeInfo}
										error={formErrors.typeInfo.length > 0 ? true : false}
									>
									{
										this.state.dataTypeInfo.map((res, i) => {
											return <MenuItem value={i+1} key={i}>{res}</MenuItem>
										})
									}
									</Select>
								</FormControl>
								{formErrors.typeInfo.length > 0 && (
									<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.typeInfo}</span>
								)}
							</div>
							<div className="col-md-12">
								<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
									<InputLabel id="demo-simple-select-outlined-label">Priority *</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										onChange={this.handleSelect}
										label="Priority *"
										name="priority"
										value={formInfo.priority}
										error={formErrors.priority.length > 0 ? true : false}
									>
									{
										this.state.dataPriority.map((res, i) => {
											return <MenuItem value={i} key={i}>{res}</MenuItem>
										})
									}
									</Select>
								</FormControl>
								{formErrors.priority.length > 0 && (
									<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.priority}</span>
								)}
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<InputTemplate
										type="text"
										id="title"
										name="title"
										label="Judul *"
										value={nullIsEmpty(formInfo.title)}
										onChange={this.handleChangeInput}
										error={formErrors.title.length > 0 ? true : false}
									/>
									{formErrors.title.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.title}</span>
									)}
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<label>Gambar</label>
									<div className="form-inline" style={{alignItems: 'initial'}}>
										<div className="col-sm-3 no-padding">
											<span className="btn btn-dark btn-file">
												Browse <input type="file" name="gambar" id="coverlabel" onChange={this.readImage} accept="image/x-png,image/jpeg" />
											</span>
										</div>
										{
											formInfo.image !== "" && (
												<div className="col-sm-9"><img src={formInfo.image} alt="gambar" style={{width: '250px'}} /></div>
											)
										}
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<InputTemplate
										type="text"
										id="url"
										name="url"
										label="URL"
										value={nullIsEmpty(formInfo.url)}
										onChange={this.handleChangeNoValidate}
									/>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<InputTemplate
										type="text"
										id="desc"
										name="desc"
										label="Deskripsi *"
										value={nullIsEmpty(formInfo.desc)}
										onChange={this.handleChangeInput}
										error={formErrors.desc.length > 0 ? true : false}
										enter={true}
									/>
									{formErrors.desc.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.desc}</span>
									)}
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="default" onClick={this.props.onClose} disabled={this.state.btnSaveDisabled}>
							Close
						</Button>
						<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
							{this.state.nameBtnSave}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		)
	}
}

export default FormDialog