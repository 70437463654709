import React, { Component, Fragment } from "react"
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios"
import jwt from "jsonwebtoken"
import parse from "html-react-parser"
import { setProgressBar } from "../../../redux/Actions/ProgressAction"
import { apiURL, jwtKey } from "../../../variable/Global"
import { Helmet } from "react-helmet";

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

class DetailPages extends Component {
	constructor(props){
		super(props)
		this.state = {
			data: [],
			pathname: "",
			metadesc: ""
		}
	}

	getData = () => {
		let id = this.props.history.location.pathname === "/privacy-policy" ? "1" : "2";
		let enid = jwt.sign(id, jwtKey)
		let postData = new FormData();
		postData.append('id', enid)
		axios.post(apiURL +"get-page", postData)
			.then(res => {
				let data = res.data.data
				this.setState({
					data: data,
					metadesc: this.props.history.location.pathname === "/privacy-policy" ? "Kebijakan privasi penggunaan aplikasi Vunime bagi pengguna" : "Syarat dan ketentuan dalam penggunaan aplikasi vunime"
				})
				this.props.setProgressBar('')
			})
	}

	componentDidMount(){
		if(this.state.pathname !== this.props.history.location.pathname){
			this.props.setProgressBar('OPEN')
			this.setState({
				pathname: this.props.history.location.pathname
			});
			this.getData();
		}
	}

	componentDidUpdate(){
		if(this.state.pathname !== this.props.history.location.pathname){
			this.props.setProgressBar('OPEN')
			this.setState({
				pathname: this.props.history.location.pathname
			});
			this.getData();
		}
	}

	render(){
		return (
			<Fragment>
				<Helmet>
					<title>{this.state.data['title']}</title>
					<meta name="description" content={this.state.metadesc} />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container">
							<div className="row mb-2">
								<div className="col-sm-6">
									<h1 className="m-0 text-dark">{this.state.data['title']}</h1>
								</div>
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<li className="breadcrumb-item"><Link to="/">Home</Link></li>
										<li className="breadcrumb-item active">{this.state.data['title']}</li>
									</ol>
								</div>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									{parse(entities.decode(this.state.data['description']))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(DetailPages)