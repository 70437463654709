import React from 'react'

const Footer = props => {
	return (
		<footer className="main-footer">
			&nbsp;
			<div className="float-right d-none d-sm-inline-block">
				<b>Copyright &copy; 2020 Alakrasi. </b> All rights reserved.
			</div>
		</footer>
	);
}

export default Footer;