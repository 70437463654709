import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
	const { layout: Layout, component: Component, to: to, menuhead: menuhead, ...rest } = props;

	return (
		<Route
			{...rest}
			render={matchProps => (
				<Layout to={to} menuhead={menuhead}>
					<Component {...matchProps} />
				</Layout>
			)}
		/>
	);
};

RouteWithLayout.propTypes = {
	component: PropTypes.any.isRequired,
	layout: PropTypes.any.isRequired,
	path: PropTypes.string,
	to: PropTypes.string,
	menuhead: PropTypes.string
};

export default RouteWithLayout;