import React, { Fragment } from 'react'
import base64url from 'base64-url'
import { withRouter, Link } from 'react-router-dom'

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const RowData = (props) => {
	return (
		<Fragment>
			<tr>
				<td style={{width: '150px'}}>{props.title}</td>
				<td style={{width: '20px'}}>:</td>
				<td>{props.value}</td>
			</tr>
		</Fragment>
	);
}

const Content = (props) => {
	return (
		<Fragment>
			<table style={{width: '100%'}}>
				<tbody>
					<tr>
						<td style={{width: '200px', verticalAlign: 'top'}}>
							<img src={props.imageCover} alt={props.title} style={{width: '100%'}} />
						</td>
						<td>
							<div className="col-md-12">
								<table className="table table-striped">
									<tbody>
										<RowData title="Judul" value={props.title} />
										<RowData title="Judul Alternatif" value={entities.decode(props.otherTitle)} />
										<RowData title="Tahun" value={props.tahun} />
										<RowData title="Total Episode" value={props.episode} />
										<tr>
											<td style={{width: '150px'}}>Rating</td>
											<td style={{width: '20px'}}>:</td>
											<td><span className="fas fa-star" style={{color: '#e99905'}}></span> {props.rating}</td>
										</tr>
										<RowData title="Vote" value={props.vote} />
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div className="row">
				{
					props.allEpisodes.map(data => {
						let epsID = base64url.encode(data.id)
						let newEpisode = data.episode_lanjutan == null ? data.episode : data.episode +" - "+ data.episode_lanjutan
						return (
							<div key={data.id} className="col-md-2" style={{padding: '5px'}}>
								<Link to={`/alyka-admin/anime/nonton/${props.id}/${epsID}`} className="btn btn-block btn-outline-secondary btn-flat">
									<strong>{newEpisode}</strong>
								</Link>
							</div>
						)
					})
				}
			</div>
		</Fragment>
	)
}

export default withRouter(Content)