import React from 'react';
import TopBarProgress from "react-topbar-progress-indicator";
import { connect } from 'react-redux';

TopBarProgress.config({
	barColors: {
		"0": "#5f6875",
		"0.5": "#4a535f",
		"1.0": "#373d45",
	},
	shadowBlur: 0,
	barThickness: 2
});

export class ProgressBar extends React.Component {
	render() {
			
		if(this.props.ProgressReducer.progressBarStatus === 'OPEN') {
			return(<TopBarProgress />)
		} else {
			return('');
		}
	}
}

const mapStateToProps = ({ ProgressReducer }) => ({
	ProgressReducer
})

export const HandleProgressBar = connect(
	mapStateToProps,
	null
)(ProgressBar);

export default HandleProgressBar;