import React from 'react'
import { withStyles, TextField } from '@material-ui/core'

export const TextFieldColor = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#d695ab',
		},
		'& label.Mui-focused.Mui-error': {
			color: 'red !important',
		},
		// '& .MuiInput-underline:after': {
		// 	borderBottomColor: '#d695ab',
		// },
		'& .MuiOutlinedInput-root': {
			// '&:hover fieldset': {
			// 	borderColor: 'white',
			// },
			'&.Mui-focused fieldset': {
				borderColor: '#d695ab',
			},
			'&.Mui-focused.Mui-error fieldset': {
				borderColor: 'red !important',
			},
		},
		width: '100%'
	},
})(TextField);

export const InputTemplate = (props) => {
	return (
		<TextFieldColor
				id={props.id}
				label={props.label}
				type={props.type}
				name={props.name}
				ref={props.refer}
				margin="normal"
				variant="outlined"
				defaultValue={props.value}
				multiline={props.enter}
				noValidate
				placeholder={props.placeholder}
				InputProps={{
					readOnly: props.readOnly,
					shrink: props.shrink
				}}
				onChange={props.onChange}
				error={props.error > 0 ? true : false}
				autoComplete="off"
			/>
	)
}