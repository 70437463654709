import React from 'react'
import nl2br from 'react-nl2br'
import Button from 'react-bootstrap/Button'
import { urlImgLoc } from '../../../../variable/Global'

const RowTable = (props) => {
	let priority = props.priority.toString() === "0" ? "Low" : "High"
	let priorityCol = props.priority.toString() === "0" ? "secondary" : "danger"
	let status = ['fas fa-folder', 'fas fa-globe-asia']
	let btnStatus = ['fas fa-globe-asia', 'fas fa-folder']
	let colorBtnStatus = ['primary', 'warning']
	let colorStatus = ['warning', 'primary']
	let type = ["", "Info", "Maintentance"]
	let image = props.image !== null && props.image !== "" ? urlImgLoc +"dialog_info/"+props.image : ""
	return (
		<tr>
			<td>
				<div style={{paddingBottom: "10px"}}>
					<span className={`badge bg-${colorStatus[props.status]}`}><i className={status[props.status]}></i></span>
					&nbsp;
					{props.title}
				</div>
				{
					(props.image !== null && props.image !== "") && (
						<img src={image} alt="" />
					)
				}
			</td>
			<td>{nl2br(props.desc)}</td>
			<td className="text-center">
				<span className={`badge bg-${priorityCol}`}>{priority}</span>
			</td>
			<td className="text-center">
				<div>{type[props.type]}</div>
			</td>
			<td>{props.url}</td>
			<td className="text-center">
				<Button size="sm" onClick={props.handleEdit} variant="default">
					<i className="fas fa-pencil-alt"></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleStatus} variant={colorBtnStatus[props.status]}>
					<i className={btnStatus[props.status]}></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleDelete} variant="danger">
					<i className="fas fa-trash"></i>
				</Button>
			</td>
		</tr>
	)
}

export default RowTable