import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import Spinner from 'react-spinkit'
import { apiURL, jwtKey } from '../../../../variable/Global'

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const RowServer = (props) => {
	return (
		<Fragment>
			<tr>
				<td style={{width: '100px'}} className="text-center">{props.server}</td>
				<td style={{width: '65px'}} className="text-center">{props.quality}</td>
				<td className="row-url">
					{
						props.server === 'uservideo'
						? <a href={props.url} target="_blank">{props.url}</a>
						: props.url
					}
				</td>
			</tr>
		</Fragment>
	)
}

class DetailAnimeBroken extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			data: [],
			detailId: null
		}
	}

	getData = (brokenId) => {
		let enId = jwt.sign(brokenId, jwtKey)
		let postData = new FormData()
		postData.append('id', enId)
		axios.post(apiURL +"detail-broken", postData)
			.then(res => {
				let data = res.data
				this.setState({ data: data })
			})
	}

	handleClose = () => {
		let newData = []
		this.setState({ data: newData, detailId: null })
		this.props.handleClose()
	}

	componentDidUpdate(){
		if(this.props.detailId !== this.state.detailId){
			this.setState({ detailId: this.props.detailId })
			this.getData(this.props.detailId)
		}
	}

	content = () => {
		if(this.state.data.length == 0){
			return (
				<div className="col-12 text-center">
					<Spinner name="line-scale-pulse-out" />
					<div>Memuat Data...</div>
				</div>
			)
		} else {
			return (
				<Fragment>
					<div className="col-12">
						<table className="table">
							<tbody>
								<tr>
									<td rowSpan="2" style={{width: "150px"}}>
										<img src={this.state.data['image_cover']} alt=""/>
									</td>
									<td valign="top">
										<table className="table table-striped">
											<tbody>
												<tr>
													<td style={{width: "75px"}}>title</td>
													<td style={{width: "10px"}}>:</td>
													<td>{parse(entities.decode(this.state.data['title']))}</td>
												</tr>
												<tr>
													<td>Episode</td>
													<td>:</td>
													<td>{this.state.data['episode']}</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="col-12">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Server</th>
									<th>Quality</th>
									<th>URL</th>
								</tr>
							</thead>
							<tbody>
							{
								this.state.data['episodes'].map(data => {
									return <RowServer
										key={data['id']}
										quality={data['quality']}
										server={data['server']}
										url={data['url']}
									/>
								})
							}	
						</tbody>
					</table>
					</div>
				</Fragment>
			)
		}
	}

	render(){
		return (
			<Fragment>
				<Modal show={this.props.showModal} onHide={this.handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title><i className="fas fa-unlink"></i> Anime Broken</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.content()}
					</Modal.Body>
				</Modal>
			</Fragment>
		)
	}
}

export default DetailAnimeBroken