import React, { Component } from 'react'
import { Logo, SideMenu } from './components'
import PropTypes from 'prop-types';

class Sidebar extends Component {
	render(){
		return (
			<aside className="main-sidebar sidebar-dark-primary elevation-4">
				<Logo />
				<SideMenu to={this.props.to} menuhead={this.props.menuhead} activeSB={this.props.activeSB} />
			</aside>
		);
	}
}

Sidebar.propTypes = {
	activeSB: PropTypes.string
};

export default Sidebar;