import React, { Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const BreadData = (props) => {
	if(props.isActive === 'false'){
		return (
			<Link to={`${props.path}`}>{props.title}</Link>
		)
	} else {
		return (
			<Fragment>{props.title}</Fragment>
		)
	}
}

const Breadcrumbs = props => {
	let active = props.isActive === 'true' ? 'active' : '';
	return (
		<li className={`breadcrumb-item ${active}`}>
			<BreadData title={props.title} path={props.path} isActive={props.isActive} />
		</li>
	);
}

Breadcrumbs.propTypes = {
	title: PropTypes.string,
	path: PropTypes.string,
	isActive: PropTypes.string
};

export default withRouter(Breadcrumbs);