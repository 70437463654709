import React, { Fragment } from 'react'
import { ShimmerImage, ShimmerText } from '../../../../variable/ShimmerData'

const ShimmerData = (props) => {
	return (
		<Fragment>
			<tr>
				<td>
					<ShimmerText size={props.size} />
				</td>
			</tr>
		</Fragment>
	)
}

const ShimmerLoad = () => {
	return (
		<Fragment>
			<table style={{width: '100%'}}>
				<tbody>
					<tr>
						<td style={{width: '200px', verticalAlign: 'top'}}>
							<ShimmerImage size="lg" />
						</td>
						<td style={{verticalAlign: 'top'}}>
							<div className="col-md-12">
								<table className="table table-striped">
									<tbody>
										<ShimmerData size="lg" />
										<ShimmerData size="md" />
										<ShimmerData size="sm" />
										<ShimmerData size="sm" />
										<ShimmerData size="sm" />
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</Fragment>
	)
}

export default ShimmerLoad