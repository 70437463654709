import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Row, Col, Form } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import jwt from 'jsonwebtoken'
import base64url from 'base64-url'
import jwt_decode from 'jwt-decode'
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { TitleContent, Breadcrumbs } from '../component'
import { apiURL, jwtKey } from '../../../variable/Global';
import './Components/components.css'
import '../../../variable/Global'
import { DialogLoading } from '../../../variable/DialogLoading';

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const TextFieldColor = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#d695ab',
		},
		'& label.Mui-focused.Mui-error': {
			color: 'red !important',
		},
		// '& .MuiInput-underline:after': {
		// 	borderBottomColor: '#d695ab',
		// },
		'& .MuiOutlinedInput-root': {
			// '&:hover fieldset': {
			// 	borderColor: 'white',
			// },
			'&.Mui-focused fieldset': {
				borderColor: '#d695ab',
			},
			'&.Mui-focused.Mui-error fieldset': {
				borderColor: 'red !important',
			},
		},
		width: '100%'
	},
})(TextField);

const formValid = (formErrors, formName) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formName).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const formURLDataValid = (formUrlError, formUrlValid) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formUrlError).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formUrlValid).forEach(val => {
		val === '' && !urlRegex.test(val) && (valid = false);
	});
	
	return valid;
};

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000
});

const InputTemplate = (props) => {
	return (
		<Fragment>
			<TextFieldColor
				id={props.id}
				label={props.label}
				type={props.type}
				name={props.name}
				multiline={props.enter}
				ref={props.refer}
				margin="normal"
				variant="outlined"
				value={props.value}
				noValidate
				placeholder={props.placeholder}
				InputProps={{
					readOnly: props.readOnly,
				}}
				onChange={props.onChange}
				error={props.error > 0 ? true : false}
			/>
		</Fragment>
	)
}

const CheckboxTemplate = (props) => {
	return (
		<Fragment>
			<Col md={3}>
				<Form.Group>
					<Form.Check className="custom-control custom-checkbox" type="checkbox" id={`default-${props.id}`}>
						<Form.Check.Input name="genres[]" type="checkbox" className="custom-control-input" checked={props.checked} value={props.id} onChange={props.onChange} />
						<Form.Check.Label className="custom-control-label">{props.title}</Form.Check.Label>
					</Form.Check>
				</Form.Group>
			</Col>
		</Fragment>
	)
}

const utilizeFocus = () => {
	const ref = React.createRef()
	const setFocus = () => {ref.current &&  ref.current.focus()}

	return {setFocus, ref} 
}

const urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i');

class AnimeUpdate extends Component {
	constructor(props){
		super(props)
		this.props.setProgressBar('OPEN')
		this.state = {
			pathname: '',
			showModal: false,
			nameButtonPublish: 'Publish',
			disabledButtonPublish: '',
			nameButtonDraft: 'Draft',
			disabledButtonDraft: '',
			checkedUnknown: true,
			dataGenre: [],
			dataSchedule: ['Complete', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu', 'Acak'],
			datajenisAnime: ['TV Series', 'OVA / ONA', 'Movie', 'Live Action', 'Special'],
			dataStatusTayang: ['Ongoing', 'Complete'],
			formName: {
				title: null,
				othertitle: '',
				desc: null,
				genre: [],
				slug: null,
				metadesc: null,
				imagecover: null,
				imagevideo: null,
				uploadDrive: '',
				schedule: null,
				tahun: null,
				episode: '',
				episodeunknown: '1',
				jenisAnime: null,
				statusTayang: null,
				myanimelist: '',
				rating: null,
				voting: null,
				action: '',
			},
			formErrors: {
				title: '',
				desc: '',
				genre: '',
				slug: '',
				metadesc: '',
				imagecover: '',
				imagevideo: '',
				schedule: '',
				tahun: '',
				episode: '',
				episodeunknown: '',
				jenisAnime: '',
				statusTayang: '',
				rating: '',
				voting: '',
				action: '',
			},
			formUrlValid: {
				urlGet: ''
			},
			formUrlError: {
				urlGet: ''
			},
			editLoad: false,
			genreLoad: false,
			showLoading: false,
		}
		this.inputFocus = utilizeFocus()
	}

	getGenre = () => {
		this.setState({ genreLoad: true })
		axios.get(apiURL +'genre')
		.then(res => {
			var decode = jwt_decode(res.data.data)
			this.setState({
				dataGenre: decode.data
			})
			this.props.setProgressBar('');
		})
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";

		switch (name) {
			case "tahun":
				if((value !== null && value !== "") && value.length < 4) {
					formErrors.tahun = "Tahun minimal 4 Angka"
				} else if((value !== null && value !== "") && value.length > 4){
					formErrors.tahun = "Tahun maksimal 4 Angka"
				}
				break;
			
			case "episode":
				formErrors[name] = "";
				if(this.state.formName.episodeunknown === '' && (value === null || value === "")){
					formErrors[name] = "Harap di isi"
				}
				break;

			default:
				break;
		}

		return formErrors[name]
	}

	validateUrl = (name, value) => {
		let formUrlError = { ...this.state.formUrlError };
		if(value === null || value === ""){
			formUrlError[name] = "Harap di isi"
		} else if(!urlRegex.test(value)){
			formUrlError[name] = "Harap masukan url valid"
		} else {
			formUrlError[name] = ""
		}

		return formUrlError[name]
	}

	handleSelect = (e) => {
		e.preventDefault()
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		const {name, value} = e.target;
		formErrors[name] = ""
		switch(name){
			case 'schedule':
				formName.schedule = value
				this.setState({ formName, formErrors })
				break
			case 'jenisAnime':
				formName.jenisAnime = value
				this.setState({ formName, formErrors })
				break
			case 'statusTayang':
				formName.statusTayang = value
				this.setState({ formName, formErrors })
				break
			default:
				break
		}
		
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";

		switch (name) {
			case "tahun":
				if((value !== null && value !== "") && value.length < 4) {
					formErrors.tahun = "Tahun minimal 4 Angka"
				} else if((value !== null && value !== "") && value.length > 4){
					formErrors.tahun = "Tahun maksimal 4 Angka"
				}
				break;

			default:
				break;
		}

		formName[name] = value
	
		this.setState({ formErrors, formName });
	}

	handleDataInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formName = { ...this.state.formName };
		formName[name] = value
		this.setState({ formName });
	}

	handleInputUrl = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formUrlError = { ...this.state.formUrlError };
		let formUrlValid = { ...this.state.formUrlValid };
		if(value === null || value === ""){
			formUrlError[name] = "Harap di isi"
		} else if(!urlRegex.test(value)){
			formUrlError[name] = "Harap masukan url valid"
		} else {
			formUrlError[name] = ""
		}

		formUrlValid[name] = value
		
		this.setState({ formUrlError, formUrlValid });
	}

	handleSubmit = (e) => {
		e.preventDefault();
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		const {name, value} = e.target;
		this.setState({
			[name]: value
		})

		formErrors.title = this.validate('title', formName.title);
		this.setState({ formErrors, title: formName.title  });

		formErrors.desc = this.validate('desc', formName.desc);
		this.setState({ formErrors, desc: formName.desc  });

		formErrors.slug = this.validate('slug', formName.slug);
		this.setState({ formErrors, slug: formName.slug  });

		formErrors.metadesc = this.validate('metadesc', formName.metadesc);
		this.setState({ formErrors, metadesc: formName.metadesc  });

		formErrors.imagecover = this.validate('imagecover', formName.imagecover);
		this.setState({ formErrors, imagecover: formName.imagecover  });

		formErrors.imagevideo = this.validate('imagevideo', formName.imagevideo);
		this.setState({ formErrors, imagevideo: formName.imagevideo  });
		
		formErrors.schedule = this.validate('schedule', formName.schedule);
		this.setState({ formErrors, schedule: formName.schedule  });
		
		formErrors.tahun = this.validate('tahun', formName.tahun);
		this.setState({ formErrors, tahun: formName.tahun  });
		
		formErrors.episode = this.validate('episode', formName.episode);
		this.setState({ formErrors, episode: formName.episode  });
		
		formErrors.jenisAnime = this.validate('jenisAnime', formName.jenisAnime);
		this.setState({ formErrors, jenisAnime: formName.jenisAnime  });
		
		formErrors.statusTayang = this.validate('statusTayang', formName.statusTayang);
		this.setState({ formErrors, statusTayang: formName.statusTayang  });
		
		formErrors.rating = this.validate('rating', formName.rating);
		this.setState({ formErrors, rating: formName.rating  });
		
		formErrors.voting = this.validate('voting', formName.voting);
		this.setState({ formErrors, voting: formName.voting  });

		if (formValid(formErrors, formName)) {
			this.setState({ showLoading: true })
			let usertoken = localStorage.usertoken;
			let decode = jwt_decode(usertoken);

			var postData = new FormData();
			postData.append('title', formName.title)
			postData.append('othertitle', formName.othertitle)
			postData.append('desc', formName.desc)
			postData.append('genres', JSON.stringify(formName.genre))
			postData.append('slug', formName.slug)
			postData.append('metadesc', formName.metadesc)
			postData.append('imagecover', formName.imagecover)
			postData.append('imagevideo', formName.imagevideo)
			postData.append('uploaddrive', formName.uploadDrive)
			postData.append('schedule', formName.schedule)
			postData.append('tahun', formName.tahun)
			postData.append('episode', formName.episode)
			postData.append('epsunknown', formName.episodeunknown)
			postData.append('jenisanime', formName.jenisAnime)
			postData.append('statustayang', formName.statusTayang)
			postData.append('myanimelist', formName.myanimelist)
			postData.append('rating', formName.rating)
			postData.append('voting', formName.voting)
			postData.append('publish', value)
			postData.append('user', decode.id)
			if(this.props.match.params.id !== undefined){
				let id = this.props.match.params.id
				let deid = base64url.decode(id)
				let enid = jwt.sign(deid, jwtKey)
				postData.append('id', enid)
			}
			axios.post(apiURL +"save-anime", postData)
				.then(res => {
					Toast.fire({
						icon: 'success',
						title: 'Berhasil disimpan'
					});
					if(this.props.match.params.urlredirect !== undefined){
						let redirect = this.props.match.params.urlredirect
						let deredirect = base64url.decode(redirect)
						let arrUrl = deredirect.split('alyka-admin')
						let newUrl = '/alyka-admin'+ arrUrl[1];
						this.props.history.push(`${newUrl}`);
					} else {
						this.props.history.push(`/alyka-admin/anime`);
					}
				})
		}
	}

	handleUrlSubmit = (e) => {
		e.preventDefault();
		let formUrlError = { ...this.state.formUrlError };
		let formUrlValid = { ...this.state.formUrlValid };

		formUrlError.urlGet = this.validateUrl('urlGet', this.state.formUrlValid.urlGet);
		formUrlValid.urlGet = this.state.formUrlValid.urlGet
		this.setState({ formUrlError, formUrlValid });
		if(formURLDataValid(this.state.formUrlError, this.state.formUrlValid)){
			var dataPost = new FormData();
			dataPost.append('urlmyanime', this.state.formUrlValid.urlGet)
			axios.post(apiURL +"get-data-anime", dataPost)
				.then(res => {
					let data = res.data
					let formUrlError = { ...this.state.formUrlError };
					let formUrlValid = { ...this.state.formUrlValid };
					let formName = { ...this.state.formName };
					formUrlError.urlGet = ""
					formUrlValid.urlGet = ""
					formName.title = data.title
					formName.othertitle = data.otherTitle
					formName.slug = data.slug
					formName.genre = data.genres
					formName.imagecover = data.cover
					formName.uploadDrive = '1'
					formName.episode = data.totalEpisode
					formName.tahun = data.tahun
					formName.jenisAnime = data.jenisAnime
					formName.myanimelist = data.urlanime
					formName.rating = data.rating
					formName.voting = data.votes
					this.setState({
						formName,
						showModal: false,
						formUrlError,
						formUrlValid
					})
				})
		}
	}

	handleUnknown = (e) => {
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		const {name, value} = e.target;
		var data;
		var checked;
		if (e.target.checked) {
			formErrors.episode = "";
			formName.episode = null;
			this.setState({ formErrors, formName  });
			data = value
			checked = true
		} else {
			data = ""
			checked = false
			formName.episode = ""
			this.setState({ formErrors, formName  });
		}
		formName[name] = data
		this.setState({
			formName,
			checkedUnknown: checked
		})
	}

	handleGenre = (e) => {
		const options = this.state.formName.genre
		let index
		if (e.target.checked) {
			options.push(e.target.value.toString())
		} else {
			index = options.indexOf(e.target.value)
			options.splice(index, 1)
		}
		this.setState({ genre: options })
	}

	genreChecked = (rowGenre) => {
		if(this.state.formName.genre !== undefined){
			if(this.state.formName.genre.includes(rowGenre)){
				return true
			} else {
				return false
			}
		}
	}

	handleDrive = (e) => {
		let formName = {...this.state.formName}
		var data;
		if (e.target.checked) {
			data = "1"
		} else {
			data = ""
		}
		formName.uploadDrive = data
		this.setState({ formName })
	}

	handleShowModal = () => {
		this.setState({
			showModal: true
		})
	}

	handleCloseModal = () => {
		let formUrlError = { ...this.state.formUrlError };
		let formUrlValid = { ...this.state.formUrlError };
		formUrlError.urlGet = ""
		formUrlValid.urlGet = ""
		this.setState({
			showModal: false,
			formUrlError,
			formUrlValid
		})
	}

	dataEdit = () => {
		this.setState({
			editLoad: true
		})
		let id = this.props.match.params.id
		let deid = base64url.decode(id)
		let enid = jwt.sign(deid, jwtKey)
		let postData = new FormData();
		postData.append('id', enid)
		axios.post(apiURL +"get-data-edit", postData)
			.then(res => {
				let data = res.data
				let formUrlError = { ...this.state.formUrlError };
				let formUrlValid = { ...this.state.formUrlValid };
				let formName = { ...this.state.formName };
				formUrlError.urlGet = ""
				formUrlValid.urlGet = ""
				formName.title = parse(entities.decode(data.title))
				formName.othertitle = parse(entities.decode(data.other_title))
				formName.slug = data.slug
				formName.genre = data.genres === undefined ? [] : data.genres
				formName.imagecover = data.image_cover
				formName.imagevideo = data.image_video
				formName.desc = data.content
				formName.slug = data.slug
				formName.metadesc = data.metadesc
				formName.uploadDrive = ''
				formName.schedule = data.hari
				formName.tahun = data.tahun
				formName.episode = data.total_episode === null ? '' : data.total_episode
				formName.episodeunknown = data.total_episode !== null ? '' : '1'
				formName.jenisAnime = data.jenis_anime
				formName.statusTayang = data.status_tayang
				formName.myanimelist = data.url_rate_vote
				formName.rating = data.rating
				formName.voting = data.voting
				this.setState({
					formName,
					showModal: false,
					formUrlError,
					formUrlValid,
					checkedUnknown: data.total_episode !== null ? false : true
				})
			}).catch(err => {
				console.log(err);
			})
	}

	componentDidMount(){
		window.scrollTo(0, 0)
		if(!this.state.genreLoad){
			this.getGenre();
		}
		if(this.props.match.params.id !== undefined && !this.state.editLoad){
			this.dataEdit()
		}
	}

	componentDidUpdate(){
		if(this.props.history.location.pathname !== this.state.pathname){
			window.scrollTo(0, 0)
			this.props.setProgressBar('OPEN');
			this.setState({
				pathname: this.props.history.location.pathname
			})
			if(!this.state.genreLoad){
				this.getGenre();
			}
			if(this.props.match.params.id !== undefined && !this.state.editLoad){
				this.dataEdit()
			}
		}
	}

	nullIsEmpty = (value) => {
		let data;
		data = value === null || value === '' ? '' : value
		return data
	}

	render(){
		var titlePage = this.props.match.params.id === undefined ? 'Tambah Anime' : 'Edit Anime'
		var iconPage = this.props.match.params.id === undefined ? 'fas fa-plus' : 'fas fa-edit'
		const { formName, formErrors, formUrlError } = this.state;
		return (
			<Fragment>
				<Helmet>
					<title>{titlePage}</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon={iconPage} title={titlePage} />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Anime" path="/alyka-admin/anime" isActive="false" />
										<Breadcrumbs title={titlePage} path="/alyka-admin/anime/update" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<Form noValidate>
							<div className="container-fluid">
								<div className="row">
									<div className="col-md-8">
										{/********************** DATA ANIME  *********************/}
										<div className="card">
											<div className="card-header">
												<h3 className="card-title"><i className="fas fa-database"></i> Data Anime</h3>
												<div className="card-tools">
													<button type="button" onClick={this.handleShowModal} className="btn btn-tool" data-tip="Load Data Myanimelist" data-for="loadAnime">
														<i className="fas fa-align-justify"></i>
													</button>
													<ReactTooltip
														id="loadAnime"
														className="extraClass"
														effect="solid"
													/>
												</div>
											</div>
											<div className="card-body">
												<InputTemplate 
													id="title"
													name="title"
													label="Judul Anime *"
													type="text"
													placeholder=""
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.title.length}
													value={this.nullIsEmpty(formName.title)}
												/>
												{formErrors.title.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.title}</span>
												)}
												<InputTemplate 
													id="othertitle"
													name="othertitle"
													label="Judul Anime Lainnya"
													type="text"
													placeholder=""
													enter={false}
													readOnly={false}
													onChange={this.handleDataInput}
													value={this.nullIsEmpty(formName.othertitle)}
												/>
												<InputTemplate 
													id="desc"
													name="desc"
													label="Sinopsis *"
													type="text"
													placeholder=""
													enter={true}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.desc.length}
													value={this.nullIsEmpty(formName.desc)}
												/>
												{formErrors.desc.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.desc}</span>
												)}
											</div>
										</div>
										{/********************** DATA GENRE  *********************/}
										<div className="card">
											<div className="card-header">
												<h3 className="card-title"><i className="fas fa-layer-group"></i> Genre</h3>
											</div>
											<div className="card-body">
												<Row>
													{
														this.state.dataGenre.map(genre => {
															return	<CheckboxTemplate key={genre.id} id={genre.id} title={genre.title} checked={this.genreChecked(genre.id)} onChange={this.handleGenre.bind(this)} />
														})
													}
												</Row>
											</div>
										</div>
										{/********************** DATA SEO  *********************/}
										<div className="card">
											<div className="card-header">
												<h3 className="card-title"><i className="fab fa-google"></i> SEO</h3>
											</div>
											<div className="card-body">
												<InputTemplate 
													id="slug"
													name="slug"
													label="Slug *"
													type="text"
													placeholder=""
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.slug.length}
													value={this.nullIsEmpty(formName.slug)}
												/>
												{formErrors.slug.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.slug}</span>
												)}
												<InputTemplate 
													id="metadesc"
													name="metadesc"
													label="Metadesc *"
													type="text"
													placeholder=""
													enter={true}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.metadesc.length}
													value={this.nullIsEmpty(formName.metadesc)}
												/>
												{formErrors.metadesc.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.metadesc}</span>
												)}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										{/********************** BUTTON SAVE  *********************/}
										<div className="card">
											<div className="card-header">
												<h3 className="card-title"><i className="far fa-save"></i> Simpan</h3>
											</div>
											<div className="card-footer">
												<button type="submit" className="btn btn-default" name="action" value="draft" disabled={this.state.disabledButtonDraft} onClick={this.handleSubmit}>{this.state.nameButtonDraft}</button>
												<button type="submit" className="btn btn-secondary float-right" name="action" value="publish" disabled={this.state.disabledButtonPublish} onClick={this.handleSubmit}>{this.state.nameButtonPublish}</button>
											</div>
										</div>
										{/********************** DATA IMAGES  *********************/}
										<div className="card">
											<div className="card-header">
												<h3 className="card-title"><i className="far fa-images"></i> Gambar</h3>
											</div>
											<div className="card-body">
												<InputTemplate 
													id="imagecover"
													name="imagecover"
													label="Cover *"
													type="text"
													placeholder="URL Gambar"
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.imagecover.length}
													value={this.nullIsEmpty(formName.imagecover)}
												/>
												{formErrors.imagecover.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.imagecover}</span>
												)}
												<InputTemplate 
													id="imagevideo"
													name="imagevideo"
													label="Background Video *"
													type="text"
													placeholder="URL Gambar"
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.imagevideo.length}
													value={this.nullIsEmpty(formName.imagevideo)}
												/>
												{formErrors.imagevideo.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.imagevideo}</span>
												)}
												<Form.Group>
													<Form.Check className="custom-control custom-checkbox" type="checkbox" id={`upload-drive`}>
														<Form.Check.Input name="upload-drive" type="checkbox" className="custom-control-input" value="1" onChange={this.handleDrive.bind(this)} />
														<Form.Check.Label className="custom-control-label">Upload ke Google Drive</Form.Check.Label>
													</Form.Check>
												</Form.Group>
											</div>
										</div>
										{/********************** DATA LAINNYA  *********************/}
										<div className="card">
											<div className="card-header">
												<h3 className="card-title"><i className="fas fa-network-wired"></i> Lainnya</h3>
											</div>
											<div className="card-body">
												<FormControl variant="outlined" style={{width: '100%'}}>
													<InputLabel id="demo-simple-select-outlined-label">Hari Tayang *</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={formName.schedule === null ? '' : formName.schedule}
														onChange={this.handleSelect}
														label="Hari Tayang"
														name="schedule"
														error={formErrors.schedule.length > 0 ? true : false}
													>
													{
														this.state.dataSchedule.map((res, i) => {
															return <MenuItem value={i} key={i}>{res}</MenuItem>
														})
													}
													</Select>
												</FormControl>
												{formErrors.schedule.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.schedule}</span>
												)}
												<InputTemplate 
													id="tahun"
													name="tahun"
													label="Tahun *"
													type="number"
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.tahun.length}
													value={this.nullIsEmpty(formName.tahun)}
												/>
												{formErrors.tahun.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.tahun}</span>
												)}
												<InputTemplate 
													id="episode"
													name="episode"
													label="Total Episode *"
													type="number"
													enter={false}
													readOnly={formName.episodeunknown === '' ? false : true}
													error={formErrors.episode.length}
													refer={this.inputFocus.ref}
													onChange={this.handleChangeInput}
													value={this.nullIsEmpty(formName.episode)}
												/>
												{formErrors.episode.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.episode}</span>
												)}
												<Form.Group>
													<Form.Check className="custom-control custom-checkbox" type="checkbox" id={`episodeunknown`}>
														<Form.Check.Input name="episodeunknown" type="checkbox" checked={this.state.checkedUnknown} className="custom-control-input" value="1" onChange={this.handleUnknown.bind(this)} />
														<Form.Check.Label className="custom-control-label">Episode Belum di Ketahui</Form.Check.Label>
													</Form.Check>
												</Form.Group>
												<FormControl variant="outlined" style={{width: '100%'}}>
													<InputLabel id="demo-simple-select-outlined-label">Jenis Anime *</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={formName.jenisAnime === null ? '' : formName.jenisAnime}
														onChange={this.handleSelect}
														label="Jenis Anime *"
														name="jenisAnime"
														error={formErrors.jenisAnime.length > 0 ? true : false}
													>
													{
														this.state.datajenisAnime.map((res, i) => {
															return <MenuItem value={i + 1} key={i + 1}>{res}</MenuItem>
														})
													}
													</Select>
												</FormControl>
												{formErrors.jenisAnime.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.jenisAnime}</span>
												)}
												<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
													<InputLabel id="demo-simple-select-outlined-label">Status Tayang *</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={formName.statusTayang === null ? '' : formName.statusTayang}
														onChange={this.handleSelect}
														label="Status Tayang *"
														name="statusTayang"
														error={formErrors.statusTayang.length > 0 ? true : false}
													>
													{
														this.state.dataStatusTayang.map((res, i) => {
															return <MenuItem value={i + 1} key={i + 1}>{res}</MenuItem>
														})
													}
													</Select>
												</FormControl>
												{formErrors.statusTayang.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.statusTayang}</span>
												)}
												<InputTemplate 
													id="myanimelist"
													name="myanimelist"
													label="myanimelist / mydramalist"
													type="text"
													enter={false}
													readOnly={false}
													placeholder="URL"
													onChange={this.handleDataInput}
													value={this.nullIsEmpty(formName.myanimelist)}
												/>
												<InputTemplate 
													id="rating"
													name="rating"
													label="Rating *"
													type="number"
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.rating.length}
													value={this.nullIsEmpty(formName.rating)}
												/>
												{formErrors.rating.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.rating}</span>
												)}
												<InputTemplate 
													id="voting"
													name="voting"
													label="Voting *"
													type="number"
													enter={false}
													readOnly={false}
													onChange={this.handleChangeInput}
													error={formErrors.voting.length}
													value={this.nullIsEmpty(formName.voting)}
												/>
												{formErrors.voting.length > 0 && (
													<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.voting}</span>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Form>
					</section>
				</div>
				<Modal show={this.state.showModal} onHide={this.handleCloseModal} size="lg">
					<Modal.Header closeButton>
						<Modal.Title><i className="fas fa-layer-group"></i> Get Data Anime</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InputTemplate 
							id="urlGet"
							name="urlGet"
							label="URL myanimelist / mydramalist *"
							type="text"
							enter={false}
							readOnly={false}
							placeholder="URL"
							onChange={this.handleInputUrl}
							error={formUrlError.urlGet.length}
						/>
						{formUrlError.urlGet.length > 0 && (
							<span className="error invalid-feedback" style={{display: "block"}}>{formUrlError.urlGet}</span>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="default" onClick={this.handleCloseModal}>
							Close
						</Button>
						<Button variant="dark" onClick={this.handleUrlSubmit}>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
				<DialogLoading showModal={this.state.showLoading} />
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(withRouter(AnimeUpdate))