import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TextField from '@material-ui/core/TextField';
import { login } from '../../../functions/Admin/Auth';
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { filter } from '../Filter/Filter'

const emailRegex = RegExp(
	/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const formValid = ({ formErrors, ...rest }) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(rest).forEach(val => {
		val === null && (valid = false);
	});

	return valid;
};

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000
});

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Login';
	}
}

class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			email: null,
			password: null,
			nameButton: ButtonName(0),
			disabledButton: "",
			redirect: false,
			formErrors: {
				email: "",
				password: ""
			}
		};
	}

	static resolve() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve('simple data');
			}, 2000);
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		let formErrors = { ...this.state.formErrors };

		//Validate Email Empty
		formErrors.email = this.state.email === null || this.state.email === "" ? "Harap isi email Anda" : (emailRegex.test(this.state.email) ? "" : "invalid email address");
		this.setState({ formErrors, email: this.state.email  });

		//Validate Password Empty
		formErrors.password = this.state.password === null || this.state.password === "" ? "Harap isi password" : (this.state.password.length < 6 ? "minimum 6 characaters required" : "");
		this.setState({ formErrors, password: this.state.password  });
	
		if (formValid(this.state)) {
			this.setState({
				nameButton: ButtonName(1),
				disabledButton: true
			});
			const postData = {
				email: this.state.email,
				password: this.state.password
			};
			login(postData).then(res => {
				let { ticon, ttitle } = '';
				switch(res){
					case 0:
						ticon = 'error';
						ttitle = 'Gagal<br> Email tidak tersedia.'
						this.setState({
							nameButton: ButtonName(0),
							disabledButton: ""
						});
						break;
					case 2:
						ticon = 'error';
						ttitle = 'Gagal<br> Email & Password tidak sesuai.'
						this.setState({
							nameButton: ButtonName(0),
							disabledButton: ""
						});
						break;
					default:
						ticon = 'success';
						ttitle = 'Berhasil'
						break;
				}
				Toast.fire({
					icon: ticon,
					title: ttitle
				});
				if(res === 1){
					this.props.setProgressBar('OPEN');
					//this.props.history.replace(`/alyka-admin`);
					window.location.href='/alyka-admin'
				}
			})
		}
	};
	
	handleChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
	
		switch (name) {
			case "email":
				formErrors.email = value === null || value === "" 
				? "Harap isi email Anda" 
				: (emailRegex.test(value)
				? ""
				: "Harap isi dengan email yang valid");
				break;

			case "password":
				formErrors.password = value === null || value === "" ? "Harap isi password" 
				: (value.length < 6 ? "minimum 6 characaters required" : "");
				break;

			default:
				break;
		}
	
		this.setState({ formErrors, [name]: value });
	};

	componentDidMount(){
		let dataFilter = filter();
		if(dataFilter === 1){
			this.props.history.push(`/alyka-admin`)
		}
		this.props.setProgressBar('');
	}

	render() {
		const { formErrors } = this.state;
		return (
			<Fragment>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Login</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="hold-transition login-page">
					<div className="login-box">
						<div className="card">
							<div className="card-body login-card-body">
								<div className="login-logo">
									<img src="/assets/images/tools/logo.png" alt="Logo Vunime" />
								</div>
								<form onSubmit={this.handleSubmit} noValidate>
									<div className="input-group col-12">
										<TextField
											id="email"
											label="Email"
											type="email"
											name="email"
											autoComplete="email"
											margin="normal"
											variant="outlined"
											noValidate
											onChange={this.handleChange}
											error={formErrors.email.length > 0 ? true : false}
											value={this.props.progressBarStatus}
											style={{width: "100%"}}
										/>
										{formErrors.email.length > 0 && (
											<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.email}</span>
										)}
										<TextField
											id="password"
											label="Password"
											type="password"
											name="password"
											margin="normal"
											variant="outlined"
											noValidate
											onChange={this.handleChange}
											error={formErrors.password.length > 0 ? true : false}
											style={{width: "100%"}}
										/>
										{formErrors.password.length > 0 && (
											<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.password}</span>
										)}
									</div>
									<div className="col-12 text-right no-padding">
										<button type="submit" className="btn btn-secondary" disabled={this.state.disabledButton}>{this.state.nameButton}</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});


export default connect(null, mapDispatchToProps)(Login);
