import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux';
import { apiURL } from '../../../../../variable/Global';
import { setCountData, setCountMessage, setCountRequest } from '../../../../../redux/Actions/CountData';

const MenuSingle = (props) => {
	let activeClass = props.title === props.active ? "active" : "";
	
	return (
		<li className="nav-item ">
			<Link to={props.path} className={`nav-link ${activeClass}`}>
				<i className={`nav-icon ${props.icon}`}></i>
				<p>
					{props.title}
					{props.count !== undefined && props.count !== "0" && (
						<span className="right badge badge-danger">{props.count}</span>
					)}
				</p>
			</Link>
		</li>
	)
}

const HeaderMenu = (props) => {
	let isActive = props.menuhead === props.title ? "active" : "";
	let isOpen = props.menuhead === props.title ? "menu-open" : "";
	return (
		<li className={`nav-item has-treeview ${isOpen}`}>
			<a className={`nav-link cursor-pointer ${isActive}`}>
				<i className={`nav-icon ${props.icon}`}></i>
				<p>
					{props.title}
					<i className="right fas fa-angle-left"></i>
				</p>
			</a>
			<ChildMenu active={props.active} menuhead={props.menuhead} childMenu={props.childMenu} />
		</li>
	)
}

const ChildMenu = (props) => {
	if(props.childMenu === 'ChildAnime') {
		return <ChildAnime active={props.active} menuhead={props.menuhead} />
	} else if(props.childMenu === 'ChildInfo') {
		return <ChildInfo active={props.active} menuhead={props.menuhead} />
	}
}

const ChildAnime = (props) => {
	return (
		<ul className="nav nav-treeview" style={props.menuhead === 'Anime' ? {display:'block'} : {}}>
			<MenuSingle
				path="/alyka-admin/anime"
				active={props.active}
				title="Semua Anime" 
				icon="far fa-list-alt" 
			/>
		</ul>
	)
}

const ChildInfo = (props) => {
	return (
		<ul className="nav nav-treeview" style={props.menuhead === 'Info' ? {display:'block'} : {}}>
			<MenuSingle
				path="/alyka-admin/info/dialog"
				active={props.active}
				title="Dialog Info" 
				icon="fab fa-wpforms" 
			/>
			<MenuSingle
				path="/alyka-admin/info/notice"
				active={props.active}
				title="Pengumuman" 
				icon="fab fa-wpforms" 
			/>
			<MenuSingle
				path="/alyka-admin/info/version"
				active={props.active}
				title="Versi App" 
				icon="fas fa-mobile-alt" 
			/>
		</ul>
	)
}

class SideMenu extends Component {
	constructor(props){
		super(props)
		this.state = {
			pathname: '',
		}
	}

	countBrokenLink = () =>{
		axios.get(apiURL +"count-broken")
			.then(res => {
				let total = res.data.total
				this.props.setCountBroken(total.toString())
			})
	}

	countMessage = () => {
		axios.get(apiURL +"count-message")
			.then(res => {
				let total = res.data.total
				this.props.setCountMessage(total.toString())
			})
	}

	countRequest = () => {
		axios.get(apiURL +"count-request")
			.then(res => {
				let total = res.data.total
				this.props.setCountRequest(total.toString())
			})
	}

	componentDidMount(){
		this.countBrokenLink();
		this.countMessage();
		this.countRequest();
	}

	componentDidUpdate(){
		if(window.location.href !== this.state.pathname){
			this.setState({
				pathname: window.location.href
			})
			this.countBrokenLink();
			this.countMessage();
			this.countRequest();
		}
	}

	render(){
		return (
			<div className="sidebar">
				<nav className="mt-2">
					<ul className="nav nav-pills nav-sidebar flex-column text-sm nav-flat nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
						<MenuSingle 
							path='/alyka-admin' 
							active={this.props.to} 
							title="Dashboard" 
							icon="fas fa-tachometer-alt"
							menuhead={this.props.menuhead}
							childMenu=""
						/>
						<HeaderMenu 
							icon="fas fa-thumbtack" 
							title="Anime"
							active={this.props.to}
							menuhead={this.props.menuhead}
							childMenu="ChildAnime"
						/>
						<MenuSingle 
							path='/alyka-admin/schedule' 
							active={this.props.to} 
							title="Jadwal" 
							icon="far fa-calendar-alt"
							menuhead={this.props.menuhead}
							childMenu=""
						/>
						<HeaderMenu 
							icon="fas fa-info-circle" 
							title="Info"
							active={this.props.to}
							menuhead={this.props.menuhead}
							childMenu="ChildInfo"
						/>
						<MenuSingle 
							path='/alyka-admin/pages' 
							active={this.props.to} 
							title="Pages" 
							icon="far fa-file-alt"
							menuhead={this.props.menuhead}
							childMenu=""
						/>
						<MenuSingle 
							path='/alyka-admin/broken' 
							active={this.props.to} 
							title="Broken Link" 
							icon="fas fa-unlink"
							menuhead={this.props.menuhead}
							childMenu=""
							count={this.props.CountReducer.countBrokenLink}
						/>
						<MenuSingle 
							path='/alyka-admin/message' 
							active={this.props.to} 
							title="Message" 
							icon="far fa-comments"
							menuhead={this.props.menuhead}
							childMenu=""
							count={this.props.CountReducer.countMessage}
						/>
						<MenuSingle 
							path='/alyka-admin/request' 
							active={this.props.to} 
							title="Request" 
							icon="fab fa-app-store"
							menuhead={this.props.menuhead}
							childMenu=""
							count={this.props.CountReducer.countRequest}
						/>
					</ul>
				</nav>
			</div>
		);
	}
}

const mapStateToProps = ({ CountReducer }) => ({
	CountReducer
})

const mapDispatchToProps = dispatch => ({
	setCountBroken: count => {
		dispatch(setCountData(count))
	},
	setCountMessage: count => {
		dispatch(setCountMessage(count))
	},
	setCountRequest: count => {
		dispatch(setCountRequest(count))
	}
});

SideMenu.propTypes = {
	to: PropTypes.string,
	menuhead: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideMenu))