const countReducer = (state = {
	countBrokenLink: '', 
	countMessage: '',
	countRequest: '',
}, action) => {
	switch (action.type) {
		case 'SET_COUNT_BROKEN_LINK':
			return Object.assign({}, state, { ...state, countBrokenLink: action.count });

		case 'SET_COUNT_MESSAGE':
			return Object.assign({}, state, { ...state, countMessage: action.count });

		case 'SET_COUNT_REQUEST':
			return Object.assign({}, state, { ...state, countRequest: action.count });

		default:
			return state;
	}
};
export default countReducer