import React from 'react';
import { Switch } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Frontend as FrontLayout } from './layouts';
import { HandleProgressBar } from './components/TopBarProgress/TopBarProgress';
import { connect } from 'react-redux'
import { setProgressBar } from './redux/Actions/ProgressAction'

import {
	Login as AdminLogin,
	Dashboard as AdminDashboard,
	Anime as AdminAnime,
	AnimeUpdate as AdminAnimeUpdate,
	Episode as AdminEpisode,
	Dialog as AdminDialog,
	Notice as AdminNotice,
	Version as AdminVersion,
	Pages as AdminPages,
	BrokenLink as AdminBrokenLink,
	Nonton as AdminNonton,
	NontonView as AdminNontonView,
	Message as AdminMessage,
	Request as AdminRequest,
	Schedule as AdminSchedule
} from './views/Admin';

import {
	Homepage,
	DetailPages
} from "./views/Frontend"

const Routes = (props) => {
	return (
		<React.Fragment>
			<HandleProgressBar />
			<Switch>
				<RouteWithLayout
					component={AdminLogin}
					exact
					layout={MinimalLayout}
					path='/alyka-admin/login'
					to=''
					menuhead=''
				/>
				<RouteWithLayout
					component={AdminDashboard}
					exact
					layout={MainLayout}
					path='/alyka-admin'
					to='Dashboard'
					menuhead=''
				/>
				<RouteWithLayout
					component={AdminAnime}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnimeUpdate}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/update'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnimeUpdate}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/update/:id/:urlredirect'
					to='Edit Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminEpisode}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/episode/:id'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminEpisode}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/episode/:id/:page'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnime}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/g/:slug'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnime}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/g/:slug/:page'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnime}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/status/:status'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnime}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/status/:status/:page'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminAnime}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/:page'
					to='Semua Anime'
					menuhead='Anime'
				/>

				{/* Halaman Admin Schedule */}
				<RouteWithLayout
					component={AdminSchedule}
					exact
					layout={MainLayout}
					path='/alyka-admin/schedule'
					to='Jadwal'
					menuhead='Schedule'
				/>
				
				{/* Halaman Admin Info */}
				<RouteWithLayout
					component={AdminDialog}
					exact
					layout={MainLayout}
					path='/alyka-admin/info/dialog'
					to='Dialog Info'
					menuhead='Info'
				/>
				<RouteWithLayout
					component={AdminDialog}
					exact
					layout={MainLayout}
					path='/alyka-admin/info/dialog/:page'
					to='Dialog Info'
					menuhead='Info'
				/>
				<RouteWithLayout
					component={AdminNotice}
					exact
					layout={MainLayout}
					path='/alyka-admin/info/notice'
					to='Pengumuman'
					menuhead='Info'
				/>
				<RouteWithLayout
					component={AdminNotice}
					exact
					layout={MainLayout}
					path='/alyka-admin/info/notice/:page'
					to='Pengumuman'
					menuhead='Info'
				/>
				<RouteWithLayout
					component={AdminVersion}
					exact
					layout={MainLayout}
					path='/alyka-admin/info/version'
					to='Versi App'
					menuhead='Info'
				/>
				<RouteWithLayout
					component={AdminVersion}
					exact
					layout={MainLayout}
					path='/alyka-admin/info/version/:page'
					to='Versi App'
					menuhead='Info'
				/>

				{/* Halaman Admin Pages */}
				<RouteWithLayout
					component={AdminPages}
					exact
					layout={MainLayout}
					path='/alyka-admin/pages'
					to='Pages'
					menuhead='Pages'
				/>

				{/* Halaman Admin Broken Link */}
				<RouteWithLayout
					component={AdminBrokenLink}
					exact
					layout={MainLayout}
					path='/alyka-admin/broken'
					to='Broken Link'
					menuhead='Broken Link'
				/>
				<RouteWithLayout
					component={AdminBrokenLink}
					exact
					layout={MainLayout}
					path='/alyka-admin/broken/:page'
					to='Broken Link'
					menuhead='Broken Link'
				/>

				{/* Halaman Admin Nonton Anime */}
				<RouteWithLayout
					component={AdminNonton}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/nonton/:id'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminNontonView}
					exact
					layout={MainLayout}
					path='/alyka-admin/anime/nonton/:id/:epsid'
					to='Semua Anime'
					menuhead='Anime'
				/>
				<RouteWithLayout
					component={AdminMessage}
					exact
					layout={MainLayout}
					path='/alyka-admin/message'
					to='Message'
					menuhead='Message'
				/>
				<RouteWithLayout
					component={AdminRequest}
					exact
					layout={MainLayout}
					path='/alyka-admin/request'
					to='Request'
					menuhead='Request'
				/>
				<RouteWithLayout
					component={Homepage}
					exact
					layout={FrontLayout}
					path='/'
				/>
				<RouteWithLayout
					component={DetailPages}
					exact
					layout={FrontLayout}
					path='/privacy-policy'
				/>
				<RouteWithLayout
					component={DetailPages}
					exact
					layout={FrontLayout}
					path='/term-condition'
				/>
			</Switch>
		</React.Fragment>
	);
};

const mapDispatchToProps = dispatch => ({
	setProgressBar: () => {
		dispatch(setProgressBar('OPEN'));
	}
});

export default connect(null, mapDispatchToProps)(Routes);