import React, { Component, Fragment } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InputTemplate } from '../../../../variable/TextInput'
import { nullIsEmpty, apiURL, jwtKey } from '../../../../variable/Global'

var parse = require('html-react-parser');

const formValid = (formErrors, formInfo) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formInfo).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

class FormReply extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			formName: {
				desc: ""
			},
			formErrors: {
				desc: ""
			},
			dataMessage: null,
			showModal: false,
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
			editorState: EditorState.createEmpty(),
		}
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formName[name] = value === "" ? null : value
	
		this.setState({ formErrors, formName });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		return formErrors[name]
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let { formErrors, formName } = this.state
		formErrors.desc = this.validate('desc', formName.desc)
		this.setState({ formErrors })
		if(formValid(formErrors, formName)){
			this.setState({
				nameBtnSave: ButtonName(1),
				btnSaveDisabled: true,
			})
			let postData = new FormData()
			postData.append('id', this.state.dataMessage.id)
			postData.append('page', this.props.page)
			postData.append('balasan', formName.desc)
			postData.append('email', this.state.dataMessage.email)
			postData.append('msg', this.state.dataMessage.deskripsi)
			axios.post(apiURL +"send-reply", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleChangeData(res.data.data)
					}
				})
		}
	}

	handleChangeData = (newData) => {
		this.props.handleChangeData(newData)
	}

	dataMessage = () => {
		let data = this.props.dataMessage
		this.setState({ dataMessage: data })
	}

	onEditorStateChange = (editorState) => {
		let formName = { ...this.state.formName }
		formName.desc = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		this.setState({
			editorState,
			formName
		});
	};

	componentDidUpdate()
	{
		if(this.state.showModal !== this.props.showModal){
			this.setState({
				showModal: this.props.showModal
			});
			if(!this.props.showModal){
				this.setState({
					nameBtnSave: ButtonName(0),
					btnSaveDisabled: false,
					formName: {
						desc: ""
					},
					formErrors: {
						desc: ""
					},
					editorState: EditorState.createEmpty(),
				})
			}
			
			if(this.props.dataMessage !== null){
				this.dataMessage()
			}
		}
	}

	render(){
		const { formName, formErrors } = this.state
		return(
			<Modal show={this.state.showModal} onHide={this.props.onClose} size="lg" backdrop="static">
				<Form noValidate>
					<Modal.Header closeButton>
						<Modal.Title><i className="fas fa-edit"></i> Page</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							{
								this.state.dataMessage !== null &&
								(
									<Fragment>
										<div className="col-md-12">
											<div style={{float: "left"}}>
												<img src={this.state.dataMessage.avatar} alt={this.state.dataMessage.fullname} className="img-circle" style={{width: "80px", paddingRight: "10px"}} />
											</div>
											<div style={{float: "left"}}>
												<div>{this.state.dataMessage.fullname}</div>
												<div>({this.state.dataMessage.nickname})</div>
												<div>{this.state.dataMessage.email}</div>
											</div>
											<div style={{clear: "both"}}></div>
										</div>
										<div className="col-md-12">&nbsp;</div>
										<div className="col-md-12" style={{padding: "5px"}}>
											<div style={{border: "1px solid #333", borderRadius: "10px", padding: "10px"}}>
												<div><b>Masukan:</b></div>
												<div>{this.state.dataMessage.deskripsi}</div>
											</div>
										</div>
									</Fragment>
								)
							}
							<div className="col-md-12">
								<div className="form-group">
								<Editor
									editorState={this.state.editorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={this.onEditorStateChange}
								/>
									{formErrors.desc.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.desc}</span>
									)}
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="default" onClick={this.props.onClose} disabled={this.state.btnSaveDisabled}>
							Close
						</Button>
						<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
							{this.state.nameBtnSave}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		)
	}
}

export default FormReply