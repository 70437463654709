import React from 'react'

const Search = (props) => {
	return (
		<form onSubmit={props.onSubmit} className="form-inline">
			<div className="input-group input-group-sm area-form-search">
				<input className="form-control form-control-navbar" type="search" name="q" placeholder="Search" aria-label="Search" onChange={props.onChange} onKeyDown={props.onKeyDown} />
				<div className="input-group-append">
					<button className="btn btn-navbar btn-navbar-search" type="submit">
						<i className="fas fa-search"></i>
					</button>
				</div>
			</div>
		</form>
	)
}

export default Search