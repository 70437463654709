import axios from 'axios'
import '../../variable/Global'
import { apiURL } from '../../variable/Global';

export const login = admin => {
	var postData = new FormData();
	postData.append('email', admin.email)
	postData.append('password', admin.password)
	return axios
	.post(apiURL +'login', postData)
	.then(res => {
		localStorage.setItem('usertoken', res.data.data)
		return res.data.result
	})
	.catch(err => {
		console.log(err);
	})
};