import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import RoutesData from './Routes';

const browserHistory = createBrowserHistory();

export default class App extends Component {
	render() {
		return (
			<Router history={browserHistory}>
				<RoutesData />
			</Router>
		);
	}
}
