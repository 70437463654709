import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import axios from 'axios'
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'
import base64url from 'base64-url'
import { Player } from 'video-react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { TitleContent, Breadcrumbs } from '../component';
import { setProgressBar } from '../../../redux/Actions/ProgressAction';
import { jwtKey, apiURL } from '../../../variable/Global';
import { ShimmerText } from '../../../variable/ShimmerData';
import '../../../../node_modules/video-react/dist/video-react.css';

class NontonView extends Component
{
	constructor(props){
		super(props)
		this.state = {
			pageTitle: "",
			pathname: "",
			id: "",
			episode: "",
			title: "",
			urlVideo: "",
			serverIdActive: "",
			dataprev: "",
			datanext: "",
			server: []
		}
	}

	getData = () => {
		this.setState({ id: this.props.match.params.id})
		let id = base64url.decode(this.props.match.params.id)
		let enid = jwt.sign(id, jwtKey)
		let epsid = base64url.decode(this.props.match.params.epsid)
		let enepsid = jwt.sign(epsid, jwtKey)
		let postData = new FormData()
		postData.append('id', enid)
		postData.append('epsid', enepsid)
		axios.post(apiURL +"nonton-episode", postData)
			.then(res => {
				let data = jwt_decode(res.data.data)
				let episode = data.episode_lanjutan === null || data.episode_lanjutan === '' ? data.episode : data.episode +" - "+ data.episode_lanjutan
				this.setState({
					title: data.title,
					episode: episode,
					urlVideo: data.url,
					server: data.list,
					pageTitle: data.title +" - "+ episode,
					serverIdActive: data.serverid,
					dataprev: base64url.encode(data.dataprev),
					datanext: base64url.encode(data.datanext),
				})
				this.props.setProgressBar('')
			})
	}

	getURLServer = (url, quality, serverid) => {
		this.setState({ urlVideo: "", serverIdActive: serverid })
		let postData = new FormData()
		postData.append('url', url)
		postData.append('quality', quality)
		axios.post(apiURL +"get-url-server", postData)
			.then(res => {
				let data = res.data.data
				this.setState({ urlVideo: data.url })
			})
	}

	componentDidMount(){
		this.setState({
			pathname: this.props.history.location.pathname
		})
		this.getData()
		window.scrollTo(0, 0)
	}

	componentDidUpdate(){
		if(this.props.history.location.pathname !== this.state.pathname){
			this.setState({
				pathname: this.props.history.location.pathname
			})
			this.getData();
			window.scrollTo(0, 0)
		}
	}

	//**********************************************************************************************************************/
	//															Komponen
	//**********************************************************************************************************************/

	viewVideo = () => {
		if(this.state.urlVideo === ""){
			return <Spinner name="ball-scale-multiple" color="fuchsia"/>
		} else {
			return (
				<Fragment>
					<Player
						playsInline
						src={this.state.urlVideo}
					/>
				</Fragment>
			)
		}
	}

	listServer = () => {
		if(this.state.server.length < 1){
			return <ShimmerText />
		} else {
			return (
				<Fragment>
					<div className="row">
						{
							this.state.server.map(data => {
								let isActive = data.id === this.state.serverIdActive ? "danger" : "outline-secondary";
								return (
									<div key={data.id} style={{padding: '5px'}} className="col-12">
										<button type="button" onClick={() => this.getURLServer(data.url, data.quality, data.id)} className={`btn btn-block btn-${isActive} btn-flat text-left`}>{`${data.server} - ${data.quality}`}</button>
									</div>
								)
							})
						}
						<div className="col-12" style={{padding: '5px'}}>
							<div className="row">
								<div className="col-5">
								{
									this.state.dataprev !== "" && (
										<Link to={`/alyka-admin/anime/nonton/${this.state.id}/${this.state.dataprev}`} className="btn btn-block btn-outline-secondary btn-flat text-left"><i className="fas fa-step-backward"></i> Prev</Link>
									)
								}
								</div>
								<div className="col-2">
									<Link to={`/alyka-admin/anime/nonton/${this.state.id}`} className="btn btn-block btn-outline-secondary btn-flat"><i className="fas fa-list"></i></Link>
								</div>
								<div className="col-5">
								{
									this.state.datanext !== "" && (
										<Link to={`/alyka-admin/anime/nonton/${this.state.id}/${this.state.datanext}`} className="btn btn-block btn-outline-secondary btn-flat text-right">Next <i className="fas fa-step-forward"></i></Link>
									)
								}
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)
		}
	}

	render(){
		return (
			<Fragment>
				<Helmet>
					<title>{this.state.pageTitle}</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="fas fa-film" title={this.state.pageTitle} />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Anime" path="/alyka-admin/anime" isActive="false" />
										<Breadcrumbs title={`Episode: ${this.state.episode}`} path="/" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-9">
									{this.viewVideo()}
								</div>
								<div className="col-md-3">
									{this.listServer()}
								</div>
							</div>
						</div>
					</section>
				</div>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(NontonView)