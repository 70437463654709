import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import { setProgressBar } from '../../../../redux/Actions/ProgressAction'
import { filter } from '../../../../views/Admin/Filter/Filter'

class MenuBar extends Component {
	constructor(props){
		super(props);
		this.state = {
			nama: '',
			pathname: ''
		}
	}

	logOut(e){
		e.preventDefault();
		this.props.setProgressBar('OPEN');
		localStorage.removeItem('usertoken');
		this.props.history.push(`/alyka-admin/login`);
	}

	componentDidMount(){
		let dataFilter = filter();
		if(dataFilter === 0){
			this.props.history.push(`/alyka-admin/login`)
		} else {
			let usertoken = localStorage.usertoken;
			let decode = jwt_decode(usertoken);
			this.setState({
				nama: decode.firstname
			});
		}
	}

	componentDidUpdate(){
		if(this.props.history.location.pathname !== this.state.pathname){
			this.setState({
				pathname: this.props.history.location.pathname
			})
			this.props.setProgressBar('OPEN');
			let dataFilter = filter();
			if(dataFilter === 0){
				this.props.history.push(`/alyka-admin/login`)
			}
		}
	}

	render() {
		return (
			<Fragment>
				<ul className="navbar-nav">
					<li className="nav-item">
						<a className="nav-link" data-widget="pushmenu"role="button"><i className="fas fa-bars"></i></a>
					</li>
				</ul>
				<ul className="navbar-nav ml-auto">
					<li className="nav-item dropdown">
						<a id="dropdownSubMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle cursor-pointer">
							Welcome, {this.state.nama}
						</a>
						<div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
							<a className="dropdown-item">
								<span className="fas fa-user"></span> Profile
							</a>
							<div className="dropdown-divider"></div>
							<a onClick={this.logOut.bind(this)} className="dropdown-item cursor-pointer">
								<span className="fas fa-sign-out-alt text-danger"></span> Logout
							</a>
						</div>
					</li>
				</ul>
			</Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(withRouter(MenuBar));