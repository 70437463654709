import React, { Component, Fragment } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { setProgressBar } from "../../../redux/Actions/ProgressAction"

class Homepage extends Component {
	constructor(props){
		super(props)
		this.state = {
			pathname: ""
		}
	}

	componentDidMount(){
		if(this.state.pathname !== this.props.history.location.pathname){
			this.props.setProgressBar('OPEN')
			this.setState({
				pathname: this.props.history.location.pathname
			});
			setTimeout(() => {
				this.props.setProgressBar('')
			}, 200);
		}
	}

	componentDidUpdate(){
		if(this.state.pathname !== this.props.history.location.pathname){
			this.props.setProgressBar('OPEN')
			this.setState({
				pathname: this.props.history.location.pathname
			});
			setTimeout(() => {
				this.props.setProgressBar('')
			}, 200);
		}
	}

	render(){
		return(
			<Fragment>
				<Helmet>
					<title>Vunime Aplikasi Nonton Anime</title>
				</Helmet>
				<div className="content-wrapper">
					<div className="content">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 text-center">
									<ul className="ss-list">
										<li>
											<img src="/assets/images/ss/ss-01.jpg" alt="Vunime" style={{height: '350px'}} />
										</li>
										<li>
											<img src="/assets/images/ss/ss-02.jpg" alt="Vunime" style={{height: '350px'}} />
										</li>
										<li>
											<img src="/assets/images/ss/ss-03.jpg" alt="Vunime" style={{height: '350px'}} />
										</li>
										<li>
											<img src="/assets/images/ss/ss-04.jpg" alt="Vunime" style={{height: '350px'}} />
										</li>
									</ul>
								</div>
								<div className="col-lg-12 text-center title-home">
									<h1>Vunime aplikasi nonton anime subtitle indonesia di Android</h1>
								</div>
								<div className="col-lg-12 text-center">
									<p>Download sekarang juga di playstore</p>
								</div>
								<div className="col-lg-12 text-center">
									<a href="https://play.google.com/store/apps/details?id=com.anime.vunime" target="_blank" rel="noopener noreferrer">
									<img src="/assets/images/tools/get-playstore.png" alt="Download di Playstore" style={{height: '50px'}} />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(Homepage)