import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const UrlCat = (props) => {
	return (
		<li>
			<Link to={props.to}>
				{props.name}
			</Link>
		</li>
	)
}

export default withRouter(UrlCat)