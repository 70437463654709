import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Reducers from './redux/Reducer/index';

import * as serviceWorker from './serviceWorker';
import App from './App';

const storeRedux = createStore(Reducers);

ReactDOM.render(<Provider store={storeRedux}><App /></Provider>, document.getElementById('root'));

serviceWorker.unregister();
