import React from "react"

const Footer = () => {
	return (
		<footer className="main-footer">
			<div className="container">
				<div className="float-right d-none d-sm-inline">
					<strong>Copyright &copy; 2020</strong>
				</div>
				<span>Vunime</span>
			</div>
		</footer>
	)
}

export default Footer