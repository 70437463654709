import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'
import Pagination from '@material-ui/lab/Pagination'
import ReactTooltip from 'react-tooltip'
import Swal from 'sweetalert2'
import { TitleContent, Breadcrumbs } from '../component'
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { apiURL, jwtKey } from '../../../variable/Global'
import { ShimmerLoad, RowTable, FormDialog } from './Components'

class Dialog extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			pathname: "",
			page: 1,
			data: [],
			showModal: false,
			dataEdit: null
		}
		this.props.setProgressBar('OPEN');
	}

	handlePage = (event, value) => {
		if(this.state.paging.toString() !== value.toString()){
			this.props.setProgressBar('OPEN')
			this.setState({
				page: value,
				data: [],
			})
			if(value === 1){
				this.props.history.replace(`/alyka-admin/info/dialog`)
			} else {
				this.props.history.replace(`/alyka-admin/info/dialog/${value}`)
			}
		}
	}

	getData = () => {
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		let postData = new FormData()
		postData.append('page', page)
		axios.post(apiURL +"dialog-info", postData)
			.then(res => {
				let data = res.data
				let deData
				if(data.result.toString() === "1"){
					deData = jwt_decode(data.data)
				} else {
					deData = null
				}
				this.setState({
					data: deData
				})
				this.props.setProgressBar('')
			})
	}

	handleShowModal = () => {
		this.setState({
			showModal: true,
			dataEdit: null
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false,
			dataEdit: null
		})
	}

	handleChangeData = (newData) => {
		let data = jwt_decode(newData)
		this.setState({
			data: data,
			showModal: false
		})
	}

	handleStatus = (id, status, index) => {
		let dataStatus = ['Draft', 'Publish']
		let changeStatus = status.toString() === "0" ? "1" : "0"
		let statusColor = changeStatus === "1" ? "#171717" : "#ffc107"
		let title = "Jadikan "+ dataStatus[changeStatus] +"?"
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		let enid = jwt.sign(id, jwtKey)
		let postData = new FormData()
		postData.append('page', page)
		postData.append('id', enid)
		postData.append('status', status)
		Swal.queue([{
			title: title,
			text: "",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: statusColor,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, '+ dataStatus[changeStatus],
			showLoaderOnConfirm: true,
			preConfirm: () => {
				axios.post(apiURL +"publish-dialog-info", postData)
					.then(res => {
						Swal.fire(
							'Berhasil!',
							'Data berhasil di update.',
							'success'
						)
						// this.state.data[index].status = res.data.status
						// this.forceUpdate()
						let dataState = { ...this.state.data }
						dataState[index].status = res.data.status
						this.setState({
							dataState
						})
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => Swal.isLoading(),
		}]);
	}

	handleDelete = (id) => {
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		let enid = jwt.sign(id, jwtKey)
		let postData = new FormData()
		postData.append('page', page)
		postData.append('id', enid)
		Swal.queue([{
			title: "Apa Anda yakin?",
			text: "Data yang di hapus tidak bisa di kembalian",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: '#dc3545',
			confirmButtonText: 'Ya, Hapus',
			cancelButtonText: 'Batal',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				axios.post(apiURL +"delete-dialog-info", postData)
					.then(res => {
						Swal.fire(
							'Berhasil!',
							'Data berhasil di hapus.',
							'success'
						)
						let data = jwt_decode(res.data.data)
						this.setState({
							data: data
						})
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => Swal.isLoading(),
		}]);
	}

	handleEdit = (value) => {
		this.setState({
			showModal: true,
			dataEdit: value
		})
	}

	componentDidMount()
	{
		if(window.location.href !== this.state.pathname)
		{
			this.setState({
				pathname: window.location.href
			})
			this.getData()
		}
	}

	componentDidUpdate()
	{
		if(window.location.href !== this.state.pathname)
		{
			this.setState({
				pathname: window.location.href
			})
			this.getData()
		}
	}

	/* Komponen data */
	contentTable = () => {
		if(this.state.data === null){
			return (
				<Fragment>
					<tr>
						<td colSpan={6} className="text-center"><i>Belum ada data</i></td>
					</tr>
				</Fragment>
			)
		} else if(this.state.data.length < 1){
			return (
				<Fragment>
					<ShimmerLoad />
					<ShimmerLoad />
				</Fragment>
			)
		} else {
			return (
				<Fragment>
					{
						this.state.data.map((value, i) => {
							return <RowTable
								key={value.id}
								title={value.judul}
								image={value.images}
								desc={value.content}
								priority={value.priority}
								type={value.tipe}
								url={value.url}
								status={value.status}
								handleStatus={() => this.handleStatus(value.id, value.status, i)}
								handleDelete={() => this.handleDelete(value.id)}
								handleEdit={() => this.handleEdit(value)}
							/>
						})
					}
				</Fragment>
			)
		}
	}

	pagingShow = () => {
		if(this.state.anime === null){
			return <Fragment></Fragment>
		} else {
			return (
				<Fragment>
					<Pagination
						count={this.state.totalPage}
						variant="outlined"
						shape="rounded"
						color="primary"
						page={this.state.page}
						onChange={this.handlePage}
						showFirstButton
						showLastButton
					/>
				</Fragment>
			)
		}
	}

	render()
	{
		return (
			<Fragment>
				<Helmet>
					<title>Dialog Info</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="far fa-list-alt" title="Dialog Info" />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Dialog Info" path="/alyka-admin/info/dialog" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">Info Dialog</h3>
											<div className="card-tools">
												<button onClick={this.handleShowModal} className="btn btn-tool" data-tip="Tambah" data-for="addDialog">
													<i className="fas fa-plus"></i>
												</button>
												<ReactTooltip 
													id="addDialog"
													className="extraClass"
													effect="solid"
												/>
											</div>
										</div>
										<div className="card-body">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<th>Judul / Gambar</th>
														<th>Deskripsi</th>
														<th className="text-center" style={{width: '100px'}}>Priority</th>
														<th className="text-center" style={{width: '125px'}}>Tipe</th>
														<th>URL</th>
														<th className="text-center" style={{width: '135px'}}>Action</th>
													</tr>
												</thead>
												<tbody>
													{this.contentTable()}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<FormDialog
					showModal={this.state.showModal}
					dataEdit={this.state.dataEdit}
					onClose={this.handleCloseModal}
					handleChangeData={(newData) => this.handleChangeData(newData)}
				/>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(Dialog)