import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Sidebar, Footer } from './components'

const Main = props => {
	const { children, to, menuhead } = props;
	return (
		<div className="wrapper">
			<Navbar />
			<Sidebar to={to} menuhead={menuhead} />
			{children}
			<Footer />
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node,
	to: PropTypes.string,
	menuhead: PropTypes.string
};

export default Main;