import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import base64url from 'base64-url'
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import Shimmer from 'react-shimmer-effect'
import ReactTooltip from 'react-tooltip'
import Pagination from '@material-ui/lab/Pagination'
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { TitleContent, Breadcrumbs } from '../component'
import { apiURL } from '../../../variable/Global'
import { DataEpisode, FormEpisode, FormServer, GetEpisode } from './components'
import './components/components.css'

const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const RowAnime = (props) => {
	return (
		<Fragment>
			<tr>
				<td style={{width: '150px'}}>{props.title}</td>
				<td style={{width: '20px'}}>:</td>
				<td>{props.value}</td>
			</tr>
		</Fragment>
	);
}

const ShimmerData = (props) => {
	return (
		<Fragment>
			<tr>
				<td>
					<Shimmer>
						<div className={`shimmer-data-${props.size}`}></div>
					</Shimmer>
				</td>
			</tr>
		</Fragment>
	)
}

class Episode extends Component
{
	constructor(props){
		super(props)
		this.props.setProgressBar('OPEN')
		const date = new Date()
		const dateTimeFormat = new Intl.DateTimeFormat('id', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
		const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date) 
		this.state = {
			pageTitle: "Episode",
			jwtKey: "4Lf@t1h.R@r@",
			postId: "",
			title: "",
			otherTitle: "",
			tahun: "",
			totalEpisode: "",
			statusTayang: 0,
			rating: "",
			voting: "",
			statusUpload: "",
			imageCover: "",
			currentEps: "",
			epsIdForm: "",
			epsForm: "",
			epsLanForm: "",
			q: "",
			dataEpisode: [],
			dataStatusTayang: ['', 'Ongoing', 'Complete'],
			dataStatusUpload: ['', 'Proses', 'Complete'],
			statusTayangColor: [
				'', 'primary', 'danger'
			],
			totalPage: 1,
			page: 1,
			paging: 1,
			pathname: '',
			showModal: false,
			showFormServer: false,
			showGrab: false,
			formEpisode: {
				eps: "",
				epsLanjutan: "",
				tglUpdate: `${day}-${month}-${year}`,
			},
			formEpisodeErrors: {

			}
		}
	}

	getData = (page) => {
		let id = base64url.decode(this.props.match.params.id)
		let enid = jwt.sign(id, this.state.jwtKey)
		this.setState({ postId: enid })
		let postData = new FormData()
		postData.append('id', enid)
		postData.append('page', page)
		axios.post(apiURL +'anime-episode', postData)
			.then(res => {
				let response		= res.data
				let data			= jwt_decode(response.data)
				let totalEps		= data.total_episode === null ? "Belum diketahui" : data.total_episode
				let statusUpload	= data.total_episode === null ? "1" : (parseInt(data.total_episode) > parseInt(data.current_episode) ? "1" : "2")
				this.setState({
					pageTitle: data.title +" Episode",
					title: data.title,
					otherTitle: data.other_title,
					tahun: data.tahun,
					totalEpisode: totalEps,
					statusTayang: data.status_tayang,
					rating: data.rating,
					voting: data.voting,
					statusUpload: statusUpload,
					imageCover: data.image_cover,
					dataEpisode: (data.dataEpisode ? data.dataEpisode : null),
					totalPage: parseInt(data.totalPage),
					page: parseInt(page),
					paging: parseInt(page),
					currentEps: data.current_episode,
				})
				this.stopProgressBar()
			})
	}

	stopProgressBar = () => {
		setTimeout(
			function() {
				this.props.setProgressBar('');
			}
			.bind(this),
			0
		);
	}

	handleShowModal = () => {
		this.setState({
			showModal: true
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false
		})
	}

	handleGrab = (tampil) => {
		this.setState({
			showGrab: tampil
		})
	}

	handleFormServerShow = (data) => {
		let enEpsId = jwt.sign(data.epsId, this.state.jwtKey)
		this.setState({ 
			showFormServer: true, 
			epsIdForm: enEpsId, 
			epsForm: data.episode, 
			epsLanForm: data.episodeLanjutan 
		})
	}

	handleFormServerClose = () => {
		this.setState({ showFormServer: false, epsForm: "" })
	}

	onChangeSearch = (e) => {
		e.preventDefault()
		const { value } = e.target
		this.setState({
			q: value
		})
	}

	contentAnime = () => {
		if(this.state.title === ""){
			return (
				<Fragment>
					<table style={{width: '100%'}}>
						<tbody>
							<tr>
								<td style={{width: '200px', verticalAlign: 'top'}}>
									<Shimmer>
										<div className="shimmer-image-cover"></div>
									</Shimmer>
								</td>
								<td style={{verticalAlign: 'top'}}>
									<div className="col-md-12">
										<table className="table table-striped">
											<tbody>
												<ShimmerData size="lg" />
												<ShimmerData size="md" />
												<ShimmerData size="sm" />
												<ShimmerData size="sm" />
												<ShimmerData size="sm" />
												<ShimmerData size="sm" />
												<ShimmerData size="sm" />
												<ShimmerData size="sm" />
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<table style={{width: '100%'}}>
						<tbody>
							<tr>
								<td style={{width: '200px', verticalAlign: 'top'}}>
									<img src={this.state.imageCover} alt={this.state.title} style={{width: '100%'}} />
								</td>
								<td>
									<div className="col-md-12">
										<table className="table table-striped">
											<tbody>
												<RowAnime title="Judul" value={this.state.title} />
												<RowAnime title="Judul Alternatif" value={entities.decode(this.state.otherTitle)} />
												<RowAnime title="Tahun" value={this.state.tahun} />
												<RowAnime title="Total Episode" value={this.state.totalEpisode} />
												<tr>
													<td style={{width: '150px'}}>Status Tayang</td>
													<td style={{width: '20px'}}>:</td>
													<td><span className={`badge bg-${this.state.statusTayangColor[this.state.statusTayang]}`}>{this.state.dataStatusTayang[this.state.statusTayang]}</span></td>
												</tr>
												<tr>
													<td style={{width: '150px'}}>Rating</td>
													<td style={{width: '20px'}}>:</td>
													<td><span className="fas fa-star" style={{color: '#e99905'}}></span> {this.state.rating}</td>
												</tr>
												<tr>
													<td style={{width: '150px'}}>Vote</td>
													<td style={{width: '20px'}}>:</td>
													<td>{this.state.voting}</td>
												</tr>
												<tr>
													<td style={{width: '150px'}}>Status Upload</td>
													<td style={{width: '20px'}}>:</td>
													<td><span className={`badge bg-${this.state.statusTayangColor[this.state.statusUpload]}`}>{this.state.dataStatusUpload[this.state.statusUpload]}</span></td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</Fragment>
			);
		}
	}

	handlePage = (event, value) => {
		if(this.state.paging.toString() !== value.toString()){
			this.props.setProgressBar('OPEN');
			this.setState({
				page: value,
				dataEpisode: [],
			})
			if(value === 1){
				this.props.history.replace(`/alyka-admin/anime/episode/${this.props.match.params.id}`)
			} else {
				this.props.history.replace(`/alyka-admin/anime/episode/${this.props.match.params.id}/${value}`)
			}
		}
	}

	pagingShow = () => {
		if(this.state.totalPage > 1){
			return (
				<div className="card-footer">
					<Pagination
						count={this.state.totalPage}
						variant="outlined"
						shape="rounded"
						color="primary"
						page={this.state.page}
						onChange={this.handlePage}
						showFirstButton
						showLastButton
					/>
				</div>
			)
		} else {
			return <Fragment></Fragment>
		}
	}

	handleChangeServer = (dataServer, page) => {
		let data			= jwt_decode(dataServer)
		let totalEps		= data.total_episode === null ? "Belum diketahui" : data.total_episode
		let statusUpload	= data.total_episode === null ? "1" : (parseInt(data.total_episode) > parseInt(data.current_episode) ? "1" : "2")
		this.setState({
			pageTitle: data.title +" Episode",
			title: data.title,
			otherTitle: data.other_title,
			tahun: data.tahun,
			totalEpisode: totalEps,
			statusTayang: data.status_tayang,
			rating: data.rating,
			voting: data.voting,
			statusUpload: statusUpload,
			imageCover: data.image_cover,
			dataEpisode: (data.dataEpisode ? data.dataEpisode : null),
			totalPage: parseInt(data.totalPage),
			page: page,
			paging: page,
			currentEps: data.current_episode,
			showModal: false,
			showFormServer: false,
			showGrab: false
		})
	}

	contentForm = () => {
		return (
			<Fragment>
				<FormEpisode
					handleShow={this.handleShowModal}
					handleClose={this.handleCloseModal}
					showModal={this.state.showModal}
					currentEps={this.state.currentEps}
					postId={this.state.postId}
					onChangeServer={(newDataServer) => this.handleChangeServer(newDataServer, 1)}
				/>
				<FormServer 
					showModal={this.state.showFormServer}
					episode={this.state.epsForm}
					postId={this.state.postId}
					epsId={this.state.epsIdForm}
					handleClose={this.handleFormServerClose}
					page={this.state.page}
					onChangeServer={(newDataServer) => this.handleChangeServer(newDataServer, this.state.page)}
				/>
				<GetEpisode
					handleShow={() => this.handleGrab(true)}
					handleClose={() => this.handleGrab(false)}
					showModal={this.state.showGrab}
					postId={this.state.postId}
					page={this.state.page}
					onChangeServer={(newDataServer) => this.handleChangeServer(newDataServer, this.state.page)}
				/>
			</Fragment>
		)
	}

	onSubmitSearch = () => {

	}

	componentDidMount(){
		this.setState({
			pathname: this.props.history.location.pathname
		})
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		this.getData(page)
		window.scrollTo(0, 0)
	}

	componentDidUpdate(){
		if(this.props.history.location.pathname !== this.state.pathname){
			this.setState({
				pathname: this.props.history.location.pathname
			})
			let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
			this.getData(page);
			window.scrollTo(0, 0)
		}
	}

	render(){
		return (
			<Fragment>
				<Helmet>
					<title>{this.state.pageTitle}</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="fas fa-film" title={this.state.pageTitle} />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Anime" path="/alyka-admin/anime" isActive="false" />
										<Breadcrumbs title={this.state.pageTitle} path="/alyka-admin/anime/update" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									{this.contentAnime()}
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">
												<div className="float-left" style={{paddingRight: '20px', paddingTop: '5px'}}><i className="fas fa-layer-group"></i> Data Episode</div>
												<form onSubmit={this.onSubmitSearch} className="form-inline float-left">
													<div className="input-group input-group-sm area-form-search">
														<input className="form-control form-control-navbar" type="search" name="q" placeholder="Cari Episode" aria-label="Search" onChange={this.onChangeSearch} autoComplete="off" />
														<div className="input-group-append">
															<button className="btn btn-navbar btn-navbar-search" type="submit">
																<i className="fas fa-search"></i>
															</button>
														</div>
													</div>
												</form>
											</h3>
											<div className="card-tools">
												<button type="button" onClick={() => this.handleGrab(true)} className="btn btn-tool" data-tip="Update Data Uservideo" data-for="updateData">
													<i className="fas fa-server"></i>
												</button>
												<ReactTooltip
													id="updateData"
													className="extraClass"
													effect="solid"
												/>
												<button type="button" onClick={this.handleShowModal} className="btn btn-tool" data-tip="Tambah Episode" data-for="addEpisode">
													<i className="fas fa-plus"></i>
												</button>
												<ReactTooltip
													id="addEpisode"
													className="extraClass"
													effect="solid"
												/>
											</div>
										</div>
										<div className="card-body">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<td style={{width: '100px'}} className="text-center">Episode</td>
														<td className="text-center">Server</td>
														<td style={{width: '130px'}} className="text-center">Action</td>
													</tr>
												</thead>
												<tbody>
													<DataEpisode
														data={this.state.dataEpisode}
														handleFormServer={(newData) => this.handleFormServerShow(newData)}
														onChangeServer={(newDataServer) => this.handleChangeServer(newDataServer, this.state.page)}
														postId={this.state.postId}
														page={this.state.page}
													/>
												</tbody>
											</table>
										</div>
										{this.pagingShow()}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				{this.contentForm()}
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(Episode)