import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'
import Pagination from '@material-ui/lab/Pagination'
import ReactTooltip from "react-tooltip"
import Swal from 'sweetalert2'
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { TitleContent, Breadcrumbs } from '../component'
import { Search, Content, ShimmerLoad } from './components'
import '../../../variable/Global'
import { apiURL, jwtKey } from '../../../variable/Global'
import FormMyanim from './components/Form/FormMyanim'


class Anime extends Component {
	constructor(props){
		super(props);
		this.state = {
			anime: [],
			page: 0,
			totalPage: 0,
			paging: 0,
			firstload: true,
			pagename: 'all',
			pathname: '',
			q: '',
			titlePage: 'Semua Anime',
			myanimeID: '',
			urlMyanime: '',
			dataIndex: 0
		}
		this.props.setProgressBar('OPEN');
	}

	stopProgressBar = () => {
		setTimeout(
			function() {
				this.props.setProgressBar('');
			}
			.bind(this),
			0
		);
	}

	isInt = (value) => {
		return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
	}

	handlePage = (event, value) => {
		if(this.state.paging.toString() !== value.toString()){
			this.props.setProgressBar('OPEN');
			this.setState({
				page: value,
				anime: [],
			})
			const query = new URLSearchParams(this.props.location.search);
			const q = query.get('q')
			const paramSearch = q === null ? '' : '?q='+ q.split(' ').join('+')
			if(value === 1){
				switch(this.setPageName()){
					case 'genre':
						this.props.history.replace(`/alyka-admin/anime/g/${this.props.match.params.slug}`);
						break;

					default:
						this.props.history.replace(`/alyka-admin/anime${paramSearch}`);
						break;
				}
			} else {
				switch(this.setPageName()){
					case 'genre':
						this.props.history.replace(`/alyka-admin/anime/g/${this.props.match.params.slug}/${value}`);
						break;

					default:
						this.props.history.replace(`/alyka-admin/anime/${value}${paramSearch}`);
						break;
				}
			}
		}
	}

	getDataAnime = (page, slug) => {
		this.props.setProgressBar('OPEN');
		const query = new URLSearchParams(this.props.location.search);
		const q = query.get('q')
		var postData = new FormData();
		postData.append('page', page)
		if(this.setPageName() === 'genre'){
			postData.append('genre', slug)
		}
		if(q !== null){
			postData.append('q', q)
		}
		
		axios.post(apiURL +'list', postData).then(res => {
			if(res.data.result === '1'){
				let data = res.data
				let decode = jwt_decode(data.data)
				this.setState({
					anime: decode.data,
					page: decode.page,
					totalPage: decode.totalPage,
					paging: page
				})
			} else {
				this.setState({
					anime: null
				})
			}
			this.stopProgressBar()
		}).catch(err => {
			console.log(err)
		})
	}

	handleDelete = (id) => {
		let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
		let enid = jwt.sign(id, jwtKey)
		let postData = new FormData()
		postData.append('page', page)
		postData.append('id', enid)
		Swal.queue([{
			title: "Apa Anda yakin?",
			text: "Data yang di hapus tidak bisa di kembalian",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: '#dc3545',
			confirmButtonText: 'Ya, Hapus',
			cancelButtonText: 'Batal',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return axios.post(apiURL +"delete-anime", postData)
					.then(res => {
						Swal.fire(
							'Berhasil!',
							'Data berhasil di hapus.',
							'success'
						)
						this.props.history.push(`/alyka-admin/anime`);
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => Swal.isLoading(),
		}]);
	}

	componentDidMount(){
		if(window.location.href !== this.state.pathname){
			this.setState({
				pathname: window.location.href
			})
			let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
			this.getDataAnime(page, this.props.match.params.slug)
		}
	}

	componentWillUnmount(){
		this.setState({
			page: 0,
			anime: []
		})
	}

	setPageName = () => {
		var pagename = '';
		if(window.location.href.includes('?q=')){
			this.setState({ titlePage: 'Pencarian Anime' })
			pagename = 'Pencarian Anime'
		} else if(window.location.href.includes('/g/')){
			this.setState({ titlePage: this.capitalizeWord(this.props.match.params.slug.split('-').join(' ')) })
			pagename = 'genre'
		} else if(window.location.href.includes('/draft')){
			pagename = 'draft'
		} else if(window.location.href.includes('/publish')){
			pagename = 'publish'
		} else if(window.location.href.includes('/tv')){
			pagename = 'tv'
		} else if(window.location.href.includes('/movie')){
			pagename = 'movie'
		} else if(window.location.href.includes('/live-action')){
			pagename = 'live'
		} else {
			pagename = 'all'
			this.setState({ titlePage: 'Semua Anime' })
		}
		return pagename;
	}

	onChangeSearch = (e) => {
		e.preventDefault()
		const { value } = e.target
		this.setState({
			q: value
		})
	}

	handleSearch = (e) => {
		e.preventDefault()
		this.props.history.replace(`/alyka-admin/anime?q=${this.state.q.split(' ').join('+')}`)
	}

	shouldBlur = (e) => {
		if (e.keyCode === 13) {
			e.target.blur();
			this.props.history.replace(`/alyka-admin/anime?q=${this.state.q.split(' ').join('+')}`)
		}
	}

	componentDidUpdate(){
		if(window.location.href !== this.state.pathname){
			this.setState({
				anime: [],
				pathname: window.location.href
			})
			let page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page;
			this.getDataAnime(page, this.props.match.params.slug)
		}
	}

	capitalizeWord = (str) => {
		return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()})
	}

	handleChangeStatus = (status, title, postID, index) => {
		// console.log(this.state.anime[index].status_tayang)

		let statusChange = status.toString() === "1" ? "Complete" : "Ongoing"
		let textSwal = status.toString() === "1" ? "Anime ini telah Complete ?" : "Anime ini akan dijadikan Ongoing ?"
		let statusColor = status.toString() === "1" ? "#171717" : "#007bff"
		let postData = new FormData()
		let enId = jwt.sign(postID, jwtKey)
		postData.append('postId', enId)
		postData.append('status', status)
		Swal.queue([{
			title: title,
			text: textSwal,
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: statusColor,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, '+ statusChange,
			showLoaderOnConfirm: true,
			preConfirm: () => {
				axios.post(apiURL +"update-status-anime", postData)
					.then(res => {
						Swal.fire(
							'Berhasil!',
							'Status anime "'+ title +'" berhasil di update.',
							'success'
						)
						// this.state.anime[index].status_tayang = res.data.status
						// this.forceUpdate()
						let dataAnime = { ...this.state.anime }
						dataAnime[index].status_tayang = res.data.status
						this.setState({ dataAnime })
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => Swal.isLoading(),
		}]);
	}

	handleChangeData = (newData, index) => {
		let dataAnime = { ...this.state.anime }
		dataAnime[index].rating = newData.rating
		dataAnime[index].tahun = newData.tahun
		dataAnime[index].total_episode = newData.total_episode
		dataAnime[index].url_rate_vote = newData.url_rate_vote
		dataAnime[index].jenis_anime = newData.jenis_anime
		this.setState({ dataAnime, showModal: false })
	}

	handleMyanim = (id, url, index) => {
		this.setState({
			showModal: true,
			myanimeID: id,
			urlMyanime: url,
			dataIndex: index
		})
	}

	handleCloseMyanim = () => {
		this.setState({
			showModal: false,
			myanimeID: '',
			urlMyanime: '',
			dataIndex: 0
		})
	}

	contentData = () => {
		if(this.state.anime === null){
			return (
				<Fragment>
					<tr>
						<td colSpan={7} className="text-center">
							<i>Anime tidak tersedia</i>
						</td>
					</tr>
				</Fragment>
			)
		} else if(this.state.anime.length < 1){
			return (
				<Fragment>
					<ShimmerLoad />
				</Fragment>
			)
		} else {
			return (
				<Fragment>
					{
						this.state.anime.map((anime, i) => {
							return <Content
								key={anime.id}
								title={anime.title}
								urlImage={anime.image_cover}
								tahun={anime.tahun}
								rating={anime.rating}
								totalEpisode={anime.total_episode}
								currentEpisode={anime.episode}
								postid={anime.id}
								jenisAnime={anime.jenis_anime}
								dateUpdate={anime.create_date}
								statusTayang={anime.status_tayang}
								statusPublish={anime.status}
								categories={anime.categories}
								onChangeStatus={() => this.handleChangeStatus(anime.status_tayang, anime.title, anime.id, i)}
								handleMyanime={() => this.handleMyanim(anime.id, anime.url_rate_vote, i)}
								handleDelete={() => this.handleDelete(anime.id)}
							/>
						})
					}
				</Fragment>
			)
		}
	}

	pagingShow = () => {
		if(this.state.anime === null){
			return <Fragment></Fragment>
		} else {
			return (
				<Fragment>
					<Pagination
						count={this.state.totalPage}
						variant="outlined"
						shape="rounded"
						color="primary"
						page={this.state.page}
						onChange={this.handlePage}
						showFirstButton
						showLastButton
					/>
				</Fragment>
			)
		}
	}

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>Anime</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="far fa-list-alt" title={this.state.titlePage} />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Anime" path="/alyka-admin/anime" isActive="true" />
									</ol>
								</div>
							</div>
							<Search onSubmit={this.handleSearch} onChange={this.onChangeSearch} onKeyDown={this.shouldBlur} />
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">Data Anime</h3>
											<div className="card-tools">
												<Link to={`/alyka-admin/anime/update`} className="btn btn-tool" data-tip="Tambah Anime" data-for="addAnime">
													<i className="fas fa-plus"></i>
												</Link>
												<ReactTooltip
													id="addAnime"
													className="extraClass"
													effect="solid"
												/>
											</div>
										</div>
										<div className="card-body">
											<table className="table table-bordered table-striped">
												<thead>
													<tr>
														<th>Judul</th>
														<th className="text-center" style={{width: '115px'}}>Tahun</th>
														<th className="text-center" style={{width: '165px'}}>Episode</th>
														<th style={{width: '150px'}}>Kategori</th>
														<th className="text-center" style={{width: '115px'}}>Jenis</th>
														<th className="text-center" style={{width: '115px'}}>Status</th>
														<th className="text-center" style={{width: '120px'}}>Update</th>
													</tr>
												</thead>
												<tbody>
													{this.contentData()}
												</tbody>
											</table>
										</div>
										<div className="card-footer">
											{this.pagingShow()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<FormMyanim
					showModal={this.state.showModal}
					onClose={this.handleCloseMyanim}
					page={this.state.page}
					animeID={this.state.myanimeID}
					url={this.state.urlMyanime}
					dataIndex={this.state.dataIndex}
					handleChangeData={(newData, index) => this.handleChangeData(newData, index)}
				/>
			</Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(withRouter(Anime))