import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'

import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { TitleContent, Breadcrumbs } from '../component'
import { DataAnime } from './components'
import { apiURL } from '../../../variable/Global'

class Scheduler extends Component
{
	constructor(props){
		super(props)
		this.state = {
			path: ''
		}
	}

	/*=====================================================================================================================
	*												ALL FUNCTION
	* =====================================================================================================================*/

	componentDidMount(){
		this.initialLoad()
	}

	componentDidUpdate(){
		this.initialLoad()
	}

	initialLoad(){
		if(window.location.href !== this.state.path){
			this.getData();
		}
	}

	getData = () => {
		this.props.setProgressBar('OPEN')
		this.setState({
			path: window.location.href
		})
		axios.get(apiURL +"schedule")
			.then(res => {
				var data = res.data
				this.setState({
					senin: data[1],
					selasa: data[2],
					rabu: data[3],
					kamis: data[4],
					jumat: data[5],
					sabtu: data[6],
					minggu: data[7],
					acak: data[8]
				})
				this.props.setProgressBar('')
			})
			.catch(err => {
				console.log(err);
			})
	}

	render(){
		return (
			<Fragment>
				<Helmet>
					<title>Jadwal</title>
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="far fa-calendar-alt" title="Jadwal" />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Dashboard" path="/console" isActive="false" />
										<Breadcrumbs title="Semua Anime" path="/anime" isActive="false" />
										<Breadcrumbs title="Jadwal" path="/anime/schedule" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<DataAnime title="Senin" data={this.state.senin} />
						<DataAnime title="Selasa" data={this.state.selasa} />
						<DataAnime title="Rabu" data={this.state.rabu} />
						<DataAnime title="Kamis" data={this.state.kamis} />
						<DataAnime title="Jumat" data={this.state.jumat} />
						<DataAnime title="Sabtu" data={this.state.sabtu} />
						<DataAnime title="Minggu" week="end" data={this.state.minggu} />
						<DataAnime title="Acak" data={this.state.acak} />
					</section>
				</div>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
})

export default connect(null, mapDispatchToProps)(withRouter(Scheduler))