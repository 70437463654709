import { combineReducers } from 'redux'
import ProgressReducer from './ProgressReducer';
import PagingReducer from './PagingReducer'
import CountReducer from './CountReducer'

const Reducers = combineReducers({
	ProgressReducer,
	PagingReducer,
	CountReducer
});

export default Reducers