import React, { Component, Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { withStyles, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { nullIsEmpty, apiGrab } from '../../../../variable/Global'

const urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i');

const TextFieldColor = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#d695ab',
		},
		'& label.Mui-focused.Mui-error': {
			color: 'red !important',
		},
		// '& .MuiInput-underline:after': {
		// 	borderBottomColor: '#d695ab',
		// },
		'& .MuiOutlinedInput-root': {
			// '&:hover fieldset': {
			// 	borderColor: 'white',
			// },
			'&.Mui-focused fieldset': {
				borderColor: '#d695ab',
			},
			'&.Mui-focused.Mui-error fieldset': {
				borderColor: 'red !important',
			},
		},
		width: '100%'
	},
})(TextField);

const InputTemplate = (props) => {
	return (
		<Fragment>
			<TextFieldColor
				id={props.id}
				label={props.label}
				type={props.type}
				name={props.name}
				ref={props.refer}
				margin="normal"
				variant="outlined"
				defaultValue={props.value}
				noValidate
				placeholder={props.placeholder}
				InputProps={{
					readOnly: props.readOnly,
					shrink: props.shrink
				}}
				onChange={props.onChange}
				error={props.error > 0 ? true : false}
				autoComplete="off"
			/>
		</Fragment>
	)
}

const formValid = (formErrors, formName) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formName).forEach(val => {
		val === null && !urlRegex.test(val) && (valid = false);;
	});
	
	return valid;
}

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

class GetEpisode extends Component
{
	constructor(props){
		super(props)
		this.state = {
			showModal: false,
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
			formName: {
				url: ""
			},
			formErrors: {
				url: ""
			}
		}
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formName[name] = value === "" ? null : value
	
		this.setState({ formErrors, formName });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		if(value === null || value === ""){
			formErrors[name] = "Harap di isi"
		} else if(!urlRegex.test(value)){
			formErrors[name] = "Harap masukan url valid"
		} else {
			formErrors[name] = ""
		}
	
		return formErrors[name]
	}

	handleDataServerChange = (newDataServer) => {
		this.props.onChangeServer(newDataServer);
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };

		formErrors.url = this.validate("url", formName.url)

		this.setState({ formErrors })

		if(formValid(this.state.formErrors, this.state.formName)){
			this.setState({
				btnSaveDisabled: true,
				nameBtnSave: ButtonName(1)
			})
			let postData = new FormData()
			postData.append('animeId', this.props.postId)
			postData.append('page', this.props.page)
			postData.append('url', this.state.formName.url)
			axios.post(apiGrab +"get-data-video", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleDataServerChange(res.data.data)
					}
					this.setState({
						btnSaveDisabled: false,
						nameBtnSave: ButtonName(0),
						formName: {
							url: ""
						}
					})
				})
		}
	}

	componentDidUpdate(){
		if(this.props.showModal !== this.state.showModal){
			let formName = { ...this.state.formName }
			formName.url = ""
			this.setState({
				showModal: this.props.showModal,
				formName
			})
			if(this.props.showModal){
				this.setState({
					formErrors: {
						url: ""
					}
				})
			}
		}
	}

	render(){
		const { formErrors, formName } = this.state;
		return (
			<Fragment>
				<Modal show={this.state.showModal} onHide={this.props.handleClose} size="md" backdrop="static">
					<Form onSubmit={this.handleSubmit} noValidate>
						<Modal.Header closeButton>
							<Modal.Title><i className="fas fa-server"></i> Update Uservideo</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="row">
								<div className="col-md-12">
									<div className="form-group">
										<InputTemplate
											type="text"
											id="url"
											name="url"
											label="URL Nanime *"
											value={nullIsEmpty(formName.url)}
											error={formErrors.url.length > 0 ? true : false}
											onChange={this.handleChangeInput}
										/>
									</div>
									{formErrors.url.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.url}</span>
									)}
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="default" onClick={this.props.handleClose} disabled={this.state.btnSaveDisabled}>
								Close
							</Button>
							<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
								{this.state.nameBtnSave}
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</Fragment>
		)
	}
}

export default GetEpisode