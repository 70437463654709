export function setCountData(count) {
	return {
		type: "SET_COUNT_BROKEN_LINK",
		count: count
	};
}

export function setCountMessage(count) {
	return {
		type: "SET_COUNT_MESSAGE",
		count: count
	};
}

export function setCountRequest(count) {
	return {
		type: "SET_COUNT_REQUEST",
		count: count
	};
}