import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-spinkit'


export const DialogLoading = (props) => {
	return (
		<Modal show={props.showModal} size="md" backdrop="static">
			<Modal.Body className="text-center">
				<Spinner name="line-scale-pulse-out" />
				<div>Sedang menyimpan...</div>
			</Modal.Body>
		</Modal>
	);
}