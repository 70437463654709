import React from 'react'
import PropTypes from 'prop-types'

const TitleContent = props => {
	return (
		<div className="col-sm-6">
			<h1 className="m-0 text-dark"><span className={props.icon}></span> {props.title}</h1>
		</div>
	);
}

TitleContent.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string
};

export default TitleContent;