import React from 'react'
import MenuBar from './MenuBar'

const Navbar = props => {
	return (
		<nav className="main-header navbar navbar-expand navbar-white navbar-light">
			<MenuBar />
		</nav>
	);
}

export default Navbar;