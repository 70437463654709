import React from 'react'
import nl2br from 'react-nl2br'
import Button from 'react-bootstrap/Button'

const RowTable = (props) => {
	let status = ['fas fa-folder', 'fas fa-globe-asia']
	let btnStatus = ['fas fa-globe-asia', 'fas fa-folder']
	let colorBtnStatus = ['primary', 'warning']
	let colorStatus = ['warning', 'primary']
	return (
		<tr>
			<td className="text-center">{props.version}</td>
			<td>{nl2br(props.desc)}</td>
			<td>{nl2br(props.changelog)}</td>
			<td className="text-center">
				<span className={`badge bg-${colorStatus[props.status]}`}><i className={status[props.status]}></i></span>
			</td>
			<td className="text-center">
				<div>{props.createDate}</div>
			</td>
			<td className="text-center">
				<Button size="sm" onClick={props.handleEdit} variant="default">
					<i className="fas fa-pencil-alt"></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleStatus} variant={colorBtnStatus[props.status]}>
					<i className={btnStatus[props.status]}></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleDelete} variant="danger">
					<i className="fas fa-trash"></i>
				</Button>
			</td>
		</tr>
	)
}

export default RowTable