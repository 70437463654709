import React from 'react'
import './components.css'

const Image = props => {
	return (
		<div className="area-image-content">
			<img src={props.urlImage} alt={props.title} />
		</div>
	)
}

export default Image