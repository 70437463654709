import React, { Component, Fragment } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import { InputTemplate } from '../../../../variable/TextInput'
import { nullIsEmpty, apiURL, jwtKey } from '../../../../variable/Global'

const formValid = (formErrors, formInfo) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formInfo).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

class FormVersion extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			formName: {
				id: "",
				version: "",
				desc: "",
				changelog: ""
			},
			formErrors: {
				version: "",
				desc: "",
				changelog: ""
			},
			showModal: false,
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
		}
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formName[name] = value === "" ? null : value
	
		this.setState({ formErrors, formName });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		return formErrors[name]
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let { formErrors, formName } = this.state
		formErrors.version = this.validate('version', formName.version)
		formErrors.desc = this.validate('desc', formName.desc)
		formErrors.changelog = this.validate('changelog', formName.changelog)
		this.setState({ formErrors })
		if(formValid(formErrors, formName)){
			this.setState({
				nameBtnSave: ButtonName(1),
				btnSaveDisabled: true,
			})
			let postData = new FormData()
			postData.append('page', this.props.page)
			postData.append('version', formName.version)
			postData.append('desc', formName.desc)
			postData.append('changelog', formName.changelog)
			if(formName.id !== ""){
				let enid = jwt.sign(formName.id, jwtKey)
				postData.append('id', enid)
			}
			axios.post(apiURL +"save-version", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleChangeData(res.data.data)
					}
				})
		}
	}

	handleChangeData = (newData) => {
		this.props.handleChangeData(newData)
	}

	dataEdit = () => {
		let formName = { ...this.state.formName }
		let data = this.props.dataEdit
		formName.version = data.app_ver
		formName.desc = data.message
		formName.changelog = data.changelog
		formName.id = data.id
		this.setState({ formName })
	}

	componentDidUpdate()
	{
		if(this.state.showModal !== this.props.showModal){
			this.setState({
				showModal: this.props.showModal
			});
			if(!this.props.showModal){
				this.setState({
					nameBtnSave: ButtonName(0),
					btnSaveDisabled: false,
					formName: {
						id: "",
						version: "",
						desc: "",
						changelog: ""
					},
					formErrors: {
						version: "",
						desc: "",
						changelog: ""
					},
				})
			}
			
			if(this.props.dataEdit !== null){
				this.dataEdit()
			}
		}
	}

	render()
	{
		const { formName, formErrors } = this.state
		return (
			<Modal show={this.state.showModal} onHide={this.props.onClose} size="md" backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title><i className="fas fa-edit"></i> Versi Aplikasi</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group">
								<InputTemplate
									type="text"
									id="version"
									name="version"
									label="Versi *"
									value={nullIsEmpty(formName.version)}
									onChange={this.handleChangeInput}
									error={formErrors.version.length > 0 ? true : false}
								/>
								{formErrors.version.length > 0 && (
									<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.version}</span>
								)}
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<InputTemplate
									type="text"
									id="desc"
									name="desc"
									label="Deskripsi *"
									value={nullIsEmpty(formName.desc)}
									onChange={this.handleChangeInput}
									error={formErrors.desc.length > 0 ? true : false}
									enter={true}
								/>
								{formErrors.desc.length > 0 && (
									<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.desc}</span>
								)}
							</div>
						</div>
						<div className="col-md-12">
							<div className="form-group">
								<InputTemplate
									type="text"
									id="changelog"
									name="changelog"
									label="Change Log *"
									value={nullIsEmpty(formName.changelog)}
									onChange={this.handleChangeInput}
									error={formErrors.changelog.length > 0 ? true : false}
									enter={true}
								/>
								{formErrors.changelog.length > 0 && (
									<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.changelog}</span>
								)}
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="default" onClick={this.props.onClose} disabled={this.state.btnSaveDisabled}>
						Close
					</Button>
					<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
						{this.state.nameBtnSave}
					</Button>
				</Modal.Footer>
			</Modal>
		)
	}
}

export default FormVersion