const initialState = {animelistupdate: true}

const pagingReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_UPDATE_ANIMELIST':
			return Object.assign({}, state, { animelistupdate: action.isBool });

		default:
			return state;
	}
};

export default pagingReducer