import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import ReactEcharts from 'echarts-for-react'
import axios from 'axios'
import { setProgressBar } from '../../../redux/Actions/ProgressAction'
import { TitleContent, Breadcrumbs } from '../component'
import { apiURL } from '../../../variable/Global'

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.props.setProgressBar('OPEN');
		this.state = {
			path: '',
			thisWeek: {
				date: [],
				total: []
			},
			prevWeek: {
				date: [],
				total: []
			},
		}
	}

	getData = () => {
		axios.get(apiURL +"dashboard")
			.then(res => {
				let { thisWeek, prevWeek } = this.state
				let dataThisWeek = res.data.thisweek
				let dataPrevWeek = res.data.prevweek
				thisWeek.date = dataThisWeek.tanggal
				thisWeek.total = dataThisWeek.jumlah
				prevWeek.date = dataPrevWeek.tanggal
				prevWeek.total = dataPrevWeek.jumlah
				this.setState({ thisWeek, prevWeek })
			})
	}

	componentDidMount() {
		this.getData();
		setTimeout(
			function () {
				this.props.setProgressBar('');
			}
				.bind(this),
			0
		);
	}

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>Dashboard</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="fas fa-tachometer-alt" title="Dashboard" />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<section className="col-lg-12">
									<div className="card card-default color-palette-box">
										<div className="card-header">
											<h3 className="card-title">
												<i className="fas fa-chart-line"></i> Pengguna
											</h3>
										</div>
										<div className="card-body">
											<ReactEcharts
												option={{
													tooltip: {
														trigger: 'axis'
													},
													legend: {
														data: ['Minggu ini', 'Minggu lalu']
													},
													grid: {
														left: '3%',
														right: '4%',
														bottom: '3%',
														containLabel: true
													},
													xAxis: [
														{
															type: "category",
															data: this.state.thisWeek.date,
															axisLine: {
																onZero: false,
																lineStyle: {
																	color: '#212529'
																}
															},
														},
														{
															type: "category",
															axisLine: {
																onZero: false,
																lineStyle: {
																	color: '#dc3545'
																}
															},
															data: this.state.prevWeek.date,
														}
													],
													yAxis: {
														type: "value"
													},
													series: [
														{
															name: 'Minggu lalu',
															data: this.state.prevWeek.total,
															lineStyle: {color: '#dc3545'},
															itemStyle: {color: '#dc3545'},
															xAxisIndex: 1,
															type: "line"
														},
														{
															name: 'Minggu ini',
															data: this.state.thisWeek.total,
															xAxisIndex: 0,
															lineStyle: {color: '#212529'},
															itemStyle: {color: '#212529'},
															type: "line"
														}
													]
												}}
											/>
										</div>
									</div>
								</section>
							</div>
						</div>
					</section>
				</div>
			</Fragment>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(Dashboard)