import React from 'react'
import Button from 'react-bootstrap/Button'

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const RowTable = (props) => {
	return (
		<tr>
			<td style={{width: "300px"}}>
				<div style={{float: "left", paddingRight: "15px"}}>
					<img src={props.avatar} alt={props.fullname} className="img-circle" style={{width: "80px"}} />	
				</div>	
				<div style={{float: "left", width: "180px"}} className="row-url">
					<div className="row-url" style={{width: "180px"}}>{props.fullname}</div>
				</div>
				<div style={{clear: "both"}}></div>
				<div className="row-url">({props.nickname})</div>
				<div className="row-url">{props.email}</div>
			</td>
			<td>
				<div>
					{props.title}
				</div>
				<div>
					{props.tahun}
				</div>
				<div>
					<b>Alasan:</b>
				</div>
				<div>
					{props.alasan}
				</div>
			</td>
			<td className="text-center">
				<div>-- Buat --</div>
				<div>{props.createDate}</div>
				<div>-- Update --</div>
				<div>{props.updateDate}</div>
			</td>
			<td className="text-center">
				<Button size="sm" onClick={props.handleReply} variant="default">
					<i className="fas fa-reply"></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleProcess} variant="success">
					<i className="fas fa-sync-alt"></i>
				</Button>
			</td>
		</tr>
	)
}

export default RowTable