import jwt_decode from 'jwt-decode'
import jwt from 'jsonwebtoken'

export function filter() {
	const token = localStorage.usertoken;
	if(token === 'undefined' || token === undefined || token === null){
		return 0;
	} else {
		const decode = jwt_decode(token)
		const dataSession = {
			id: decode.id,
			email: decode.email,
			firstname: decode.firstname,
			lastname: decode.lastname,
			title: decode.title,
			avatar: decode.avatar,
			role: decode.role
		}
		if(Date.now() < (decode.exp * 1000)){
			let usertoken = jwt.sign(dataSession, '4Lf@t1h.R@r@', {
				expiresIn: 3600
			});
			localStorage.setItem('usertoken', usertoken);
			return 1;
		} else {
			return 0;
		}
	}
}