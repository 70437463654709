import React from 'react'
import Button from 'react-bootstrap/Button'

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const RowTable = (props) => {
	let status = ['fas fa-folder', 'fas fa-globe-asia']
	let btnStatus = ['fas fa-globe-asia', 'fas fa-folder']
	let colorBtnStatus = ['primary', 'warning']
	let colorStatus = ['warning', 'primary']
	return (
		<tr>
			<td>{props.title}</td>
			<td>{parse(entities.decode(props.desc))}</td>
			<td className="text-center">
				<span className={`badge bg-${colorStatus[props.status]}`}><i className={status[props.status]}></i></span>
			</td>
			<td className="text-center">
				<div>-- Buat --</div>
				<div>{props.createDate}</div>
				<div>-- Update --</div>
				<div>{props.updateDate}</div>
			</td>
			<td className="text-center">
				<Button size="sm" onClick={props.handleEdit} variant="default">
					<i className="fas fa-pencil-alt"></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleStatus} variant={colorBtnStatus[props.status]}>
					<i className={btnStatus[props.status]}></i>
				</Button>
				&nbsp;
				<Button size="sm" onClick={props.handleDelete} variant="danger">
					<i className="fas fa-trash"></i>
				</Button>
			</td>
		</tr>
	)
}

export default RowTable