import React, { Component, Fragment } from 'react'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { InputTemplate } from '../../../../../variable/TextInput'
import { jwtKey, apiURL } from '../../../../../variable/Global';

const formValid = (formErrors, formInfo) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formInfo).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

class FormMyanim extends Component
{
	constructor(props){
		super(props)
		this.state = {
			formName: {
				id: "",
				url: ""
			},
			formErrors: {
				url: ""
			},
			showModal: false,
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
		}
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formName[name] = value === "" ? null : value
	
		this.setState({ formErrors, formName });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		return formErrors[name]
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let { formErrors, formName } = this.state
		formErrors.title = this.validate('url', formName.title)
		this.setState({ formErrors })
		if(formValid(formErrors, formName)){
			this.setState({
				nameBtnSave: ButtonName(1),
				btnSaveDisabled: true,
			})
			let postData = new FormData()
			postData.append('id', formName.id)
			postData.append('url', formName.url)
			axios.post(apiURL +"save-myanime", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleChangeData(res.data.data)
					}
				})
		}
	}

	handleChangeData = (newData) => {
		this.props.handleChangeData(newData, this.props.dataIndex)
	}

	getDataUrl = () => {
		let formName	= { ...this.state.formName }
		formName.id		= jwt.sign(this.props.animeID, jwtKey)
		formName.url	= this.props.url
		this.setState({ formName })
	}

	componentDidUpdate()
	{
		if(this.state.showModal !== this.props.showModal){
			this.setState({
				showModal: this.props.showModal
			});
			if(!this.props.showModal){
				this.setState({
					nameBtnSave: ButtonName(0),
					btnSaveDisabled: false,
					formName: {
						id: "",
						url: ""
					},
					formErrors: {
						id: "",
						url: ""
					},
				})
			} else {
				this.getDataUrl();
			}
		}
	}

	render()
	{
		const { formName, formErrors } = this.state
		return (
			<Modal show={this.state.showModal} onHide={this.props.onClose} size="lg" backdrop="static">
				<Form noValidate onSubmit={this.handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title><i className="fas fa-edit"></i> Pengumuman</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<InputTemplate
										type="text"
										id="url"
										name="url"
										label="URL Myanimelist *"
										value={formName.url}
										onChange={this.handleChangeInput}
										error={formErrors.url.length > 0 ? true : false}
									/>
									{formErrors.url.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.url}</span>
									)}
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="default" onClick={this.props.onClose} disabled={this.state.btnSaveDisabled}>
							Close
						</Button>
						<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
							{this.state.nameBtnSave}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		)
	}
}

export default FormMyanim