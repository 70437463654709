import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import Moment from 'react-moment'
import base64url from 'base64-url'
import { Image, UrlCat } from './components'

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

class Content extends Component {
	constructor(props){
		super(props);
		this.state = {
			totalEpisode: "",
			statusUpload: "",
			genres: [],
			secretKey: "4Lf@t1h.R@r@",
			jenisAnime: [
				'', 'TV', 'OVA', 'Movie', 'Live Action', 'Special'
			],
			jenisColor: [
				'', 'primary', 'success', 'secondary', 'danger', 'warning'
			],
			statusTayang: [
				'', 'Ongoing', 'Complete'
			],
			statusTayangColor: [
				'', 'primary', 'danger'
			],
			statusPublish: [
				'fas fa-folder', 'fas fa-globe-asia'
			],
			statusPublishColor: [
				'warning', 'primary'
			],
		}
	}

	componentDidMount(){
		this.setState({
			totalEpisode: this.props.totalEpisode === null ? <span className="text-danger" style={{fontWeight: 'bold'}}>???</span> : this.props.totalEpisode,
			statusUpload: this.props.totalEpisode === null ? <span className="badge bg-primary">Proses</span> : (parseInt(this.props.currentEpisode) < parseInt(this.props.totalEpisode) ? <span className="badge bg-primary">Proses</span> : <span className="badge bg-danger">Complete</span>)
		})

		this.setState({
			genres: this.props.categories
		})
	}

	showGenre = () => {
		if(this.state.genres.length > 0){
			return (
				<Fragment>
					{
						this.state.genres.map(genre => {
							return <UrlCat key={genre.id} to={`/alyka-admin/anime/g/${genre.slug}`} name={genre.title} />
						})
					}
				</Fragment>
			)
		} else {
			return <Fragment></Fragment>
		}
	}
	
	render() {
		var cipherID = base64url.encode(this.props.postid.toString())
		var chiperRedirect = base64url.encode(window.location.href)
		return (
			<Fragment>
				<tr>
					<td>
						<Image urlImage={this.props.urlImage} title={this.props.title} />
						{parse(entities.decode(this.props.title))}
						<div className="uplink">
							<ul>
								<li>
									<Link to={`/alyka-admin/anime/update/${cipherID}/${chiperRedirect}`}><i className="fas fa-edit"></i> Edit</Link>
								</li>
								<li>
									<a onClick={this.props.handleDelete} className="text-danger cursor-pointer"><i className="far fa-trash-alt"></i> Hapus</a>
								</li>
								<li>
									<Link to={`/alyka-admin/anime/nonton/${cipherID}`}><i className="fas fa-tv"></i> Nonton</Link>
								</li>
								<li>
									<a onClick={this.props.handleMyanime} className="cursor-pointer"><i className="fas fa-blog"></i> Myanimelist</a>
								</li>
							</ul>
						</div>
					</td>
					<td className="text-center">
						{this.props.tahun}
						<div>
							<span className="fas fa-star content-rating"></span> {this.props.rating}
						</div>
					</td>
					<td>
						<div>
							{this.state.totalEpisode}
							<Link to={`/alyka-admin/anime/episode/${cipherID}`} className="float-right cursor-pointer">
								<span className="far fa-edit"></span>
							</Link>
						</div>
						<div className="status-upload">
							<div className="title-status-upload">
								-- Status Upload --
							</div>
							<div className="status-upload-name">
								{this.state.statusUpload}
							</div>
							<div className="last-episode">
								Last Eps: <b>{this.props.currentEpisode}</b>
							</div>
						</div>
					</td>
					<td>
						<ul className="genre-list-content">
							{this.showGenre()}
						</ul>
					</td>
					<td className="text-center">
						<span className={`badge bg-${this.state.jenisColor[this.props.jenisAnime]}`}>{this.state.jenisAnime[this.props.jenisAnime]}</span>
					</td>
					<td className="text-center">
						<div className="area-status-tayang">
							<div>
								<span className={`badge bg-${this.state.statusPublishColor[this.props.statusPublish]}`}><span className={this.state.statusPublish[this.props.statusPublish]}></span></span>
								&nbsp;
								<span className={`badge bg-${this.state.statusTayangColor[this.props.statusTayang]} cursor-pointer`} onClick={this.props.onChangeStatus}>{this.state.statusTayang[this.props.statusTayang]}</span>
							</div>
						</div>
					</td>
					<td className="text-center">
						<Moment format="DD MMMM YYYY HH:mm">
							{this.props.dateUpdate}
						</Moment> WIB
					</td>
				</tr>
			</Fragment>
		)
	}
}

export default withRouter(Content)