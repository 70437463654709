import React, { Component, Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InputTemplate } from '../../../../variable/TextInput'
import { nullIsEmpty, apiURL, jwtKey } from '../../../../variable/Global'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const formValid = (formErrors, formInfo) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formInfo).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

class FormNotice extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			formName: {
				id: "",
				title: "",
				desc: ""
			},
			formErrors: {
				title: "",
				desc: ""
			},
			showModal: false,
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
			editorState: EditorState.createEmpty(),
		}
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formName = { ...this.state.formName };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formName[name] = value === "" ? null : value
	
		this.setState({ formErrors, formName });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		return formErrors[name]
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let { formErrors, formName } = this.state
		formErrors.title = this.validate('title', formName.title)
		formErrors.desc = this.validate('desc', formName.desc)
		this.setState({ formErrors })
		if(formValid(formErrors, formName)){
			this.setState({
				nameBtnSave: ButtonName(1),
				btnSaveDisabled: true,
			})
			let postData = new FormData()
			postData.append('page', this.props.page)
			postData.append('title', formName.title)
			postData.append('desc', formName.desc)
			if(formName.id !== ""){
				let enid = jwt.sign(formName.id, jwtKey)
				postData.append('id', enid)
			}
			axios.post(apiURL +"save-notice", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleChangeData(res.data.data)
					}
				})
		}
	}

	handleChangeData = (newData) => {
		this.props.handleChangeData(newData)
	}

	dataEdit = () => {
		let formName = { ...this.state.formName }
		let editorState = { ...this.state.editorState }
		let data = this.props.dataEdit
		formName.title = data.title
		formName.desc = data.desc
		formName.id = data.id
		editorState = EditorState.createWithContent(
			ContentState.createFromBlockArray(
				convertFromHTML(parse(data.desc))
			)
		)
		this.setState({ formName, editorState })
	}

	onEditorStateChange = (editorState) => {
		let formName = { ...this.state.formName }
		formName.desc = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		this.setState({
			editorState,
			formName
		});
	};

	componentDidUpdate()
	{
		if(this.state.showModal !== this.props.showModal){
			this.setState({
				showModal: this.props.showModal
			});
			if(!this.props.showModal){
				this.setState({
					nameBtnSave: ButtonName(0),
					btnSaveDisabled: false,
					formName: {
						id: "",
						title: "",
						desc: ""
					},
					formErrors: {
						title: "",
						desc: ""
					},
				})
			}
			
			if(this.props.dataEdit !== null){
				this.dataEdit()
			}
		}
	}

	render()
	{
		const { formName, formErrors } = this.state
		return (
			<Modal show={this.state.showModal} onHide={this.props.onClose} size="md" backdrop="static">
				<Form noValidate>
					<Modal.Header closeButton>
						<Modal.Title><i className="fas fa-edit"></i> Pengumuman</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<InputTemplate
										type="text"
										id="title"
										name="title"
										label="Judul *"
										value={entities.decode(nullIsEmpty(formName.title))}
										onChange={this.handleChangeInput}
										error={formErrors.title.length > 0 ? true : false}
									/>
									{formErrors.title.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.title}</span>
									)}
								</div>
							</div>
							{/* <div className="col-md-12">
								<div className="form-group">
									<InputTemplate
										type="text"
										id="desc"
										name="desc"
										label="Deskripsi *"
										value={nullIsEmpty(formName.desc)}
										onChange={this.handleChangeInput}
										error={formErrors.desc.length > 0 ? true : false}
										enter={true}
									/>
									{formErrors.desc.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.desc}</span>
									)}
								</div>
							</div> */}
							<div className="col-md-12">
								<div className="form-group">
								<Editor
									editorState={this.state.editorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									editorClassName="editorClassName"
									onEditorStateChange={this.onEditorStateChange}
								/>
								{formErrors.desc.length > 0 && (
									<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.desc}</span>
								)}
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="default" onClick={this.props.onClose} disabled={this.state.btnSaveDisabled}>
							Close
						</Button>
						<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
							{this.state.nameBtnSave}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		)
	}
}

export default FormNotice