import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Shimmer from 'react-shimmer-effect'
import jwt from 'jsonwebtoken'
import axios from 'axios'
import Swal from 'sweetalert2'
import { apiURL } from '../../../../variable/Global'

const RowData = (props) => {
	let episode = props.epsLanjutan !== null ? props.eps +" - "+ props.epsLanjutan : (props.option !== null && props.option !== undefined ? props.eps +" - "+ props.option : props.eps)
	let classEps = props.dataServer.some(item => item.server === 'uservideo') ? 'eps-uservideo' : ''
	return (
		<Fragment>
			<tr>
				<td className={`text-center ${classEps}`}>{episode}</td>
				<td>
					<table className="table table-bordered">
						<tbody>
						{
							props.dataServer.map(data => {
								return <RowServer onDeleteServer={(serverId) => props.onDeleteServer(serverId)} key={data.id} serverId={data.id} quality={data.quality} server={data.server} url={data.url} />
							})
						}
						</tbody>
					</table>
				</td>
				<td className="text-center">
					<Button onClick={props.onDeleteEpisode} variant="danger" size="sm">
						<i className="fas fa-trash"></i>
					</Button>
					&nbsp;
					<Button variant="dark" size="sm" onClick={props.onClick}>
						<i className="fas fa-plus"></i>
					</Button>
				</td>
			</tr>
		</Fragment>
	)
}

const RowServer = (props) => {
	return (
		<Fragment>
			<tr>
				<td style={{width: '65px'}} className="text-center">{props.quality}</td>
				<td style={{width: '100px'}} className="text-center">{props.server}</td>
				<td className="row-url">
					{
						props.server === 'uservideo'
						? <a href={props.url} target="_blank">{props.url}</a>
						: props.url
					}
				</td>
				<td style={{width: '100px'}} className="text-center">
					<Button onClick={() => props.onDeleteServer(props.serverId)} size="sm" variant="danger">
						<i className="fas fa-trash"></i>
					</Button>
				</td>
			</tr>
		</Fragment>
	)
}

const LoadingTable = () => {
	return (
		<Fragment>
			<td>
				<Shimmer>
					<div className="shimmer-data-full"></div>
				</Shimmer>
			</td>
		</Fragment>
	)
}

class DataEpisode extends Component
{
	constructor(props){
		super(props)
		this.state = {
			jwtKey: "4Lf@t1h.R@r@",
		}
	}

	contentData = () => {
		
		if(this.props.data === null){
			return (
				<Fragment>
					<tr>
						<td colSpan={3} className="text-center">
							<i>Data episode tidak tersedia</i>
						</td>
					</tr>
				</Fragment>
			)
		} else if(this.props.data.length > 0){
			return (
				<Fragment>
					{
						this.props.data.map(data => {
							let newData = {
								epsId: data.id,
								episode: data.episode,
								episodeLanjutan: data.episode_lanjutan
							}
							return <RowData
								key={data.id}
								epsId={data.id}
								eps={data.episode}
								epsLanjutan={data.episode_lanjutan}
								option={data.option}
								dataServer={data.dataServer}
								onClick={() => this.handleSendEpsId(newData)}
								onDeleteServer={(serverId) => this.handleDeleteServer(serverId)}
								onDeleteEpisode={() => this.handleDeleteEpisode(data.id, data.episode)}
							/>
						})
					}
				</Fragment>
			)

		}else {
			return (
				<Fragment>
					<tr>
						<LoadingTable />
						<LoadingTable />
						<LoadingTable />
					</tr>
					<tr>
						<LoadingTable />
						<LoadingTable />
						<LoadingTable />
					</tr>
					<tr>
						<LoadingTable />
						<LoadingTable />
						<LoadingTable />
					</tr>
				</Fragment>
			)
		}
	}

	handleSendEpsId = (dataEps) => {
		this.props.handleFormServer(dataEps)
	}

	handleDataServerChange = (newDataServer) => {
		this.props.onChangeServer(newDataServer);
	}

	handleDeleteServer = (serverId) => {
		let enId = jwt.sign(serverId, this.state.jwtKey)
		let postData = new FormData()
		postData.append('serverId', enId)
		postData.append('postId', this.props.postId)
		postData.append('page', this.props.page)
		Swal.queue([{
			title: "Yakin akan dihapus?",
			text: "Data yang sudah dihapus tidak bisa dikembalikan",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#171717",
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, Hapus',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return axios.post(apiURL +"delete-server", postData)
					.then(res => {
						if(res.data.result.toString() === "1"){
							this.handleDataServerChange(res.data.data)
						}
						Swal.fire(
							'Berhasil!',
							'Data berhasil di hapus.',
							'success'
						)
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}]);
	}

	handleDeleteEpisode = (epsId, episode) => {
		let enId = jwt.sign(epsId, this.state.jwtKey)
		let postData = new FormData()
		postData.append('id', enId)
		postData.append('postId', this.props.postId)
		Swal.queue([{
			title: "Yakin akan menghapus episode "+ episode +"?",
			text: "Data yang sudah dihapus tidak bisa dikembalikan",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#171717",
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, Hapus',
			showLoaderOnConfirm: true,
			preConfirm: () => {
				return axios.post(apiURL +"delete-episode", postData)
					.then(res => {
						if(res.data.result.toString() === "1"){
							this.handleDataServerChange(res.data.data)
						}
						Swal.fire(
							'Berhasil!',
							'Episode berhasil di hapus.',
							'success'
						)
					})
					.catch(err => {
						console.log(err)
					})
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}]);
	}

	render(){
		return (
			<Fragment>
				{this.contentData()}
			</Fragment>
		)
	}
}

export default DataEpisode