import React from 'react'
import Button from 'react-bootstrap/Button';

var parse = require('html-react-parser');
const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

const RawTable = (props) => {
	return (
		<tr>
			<td>
				<div className="float-left area-image-content">
					<img src={props.imageCover} alt={props.title}/>
				</div>
				<div>
					<a onClick={props.showDetailBroken}>{parse(entities.decode(props.title))}</a>
				</div>
				<div style={{paddingTop: '20px'}}>
					<span className="area-episode">Episode: {props.episode}</span>
				</div>
			</td>
			<td className="text-center">
				<div>{props.createDate}</div>
			</td>
			<td className="text-center">
				<Button size="sm" onClick={props.handleProcess} variant="success">
					<i className="fas fa-check"></i>
				</Button>
			</td>
		</tr>
	)
}

export default RawTable;