import React, { Component } from 'react'

class DataAnime extends Component
{
	render(){
		var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
		var d = new Date();
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12">
						<div className={`card ${days[d.getDay()] !== this.props.title ? 'collapsed-card' : 'card-primary'}`}>
							<div className="card-header">
								<h3 className={`card-title ${this.props.week === 'end' ? 'text-danger' : ''}`}>
									<i className="far fa-calendar-alt"></i> {this.props.title}
								</h3>
								<div class="card-tools">
									<button type="button" class="btn btn-tool" data-card-widget="collapse">
										<i class={`fas ${days[d.getDay()] !== this.props.title ? 'fa-plus' : 'fa-minus'}`}></i>
									</button>
								</div>
							</div>
							<div className="card-body">
								<div className="schedule-row">
									{
										this.props.data !== undefined && this.props.data.length > 0 ?
										this.props.data.map(row => {
											return (
												<div key={row.id} className="schedule-column">
													<div className="schedule-tools">
														<a>Edit</a>
														<a className="float-right">Eps</a>
													</div>
													<img src={row.image_cover} alt=""/>
													<div className="schedule-title">{row.title}</div>
												</div>
											)
										})
										: <></>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DataAnime