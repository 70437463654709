import React from 'react'
import { ShimmerImage, ShimmerText } from '../../../../variable/ShimmerData'

const ShimmerLoad = () => {
	return (
		<tr>
			<td>
				<div className="float-left" style={{paddingRight: '10px'}}>
					<ShimmerImage />
				</div>
				<ShimmerText size="md" />
			</td>
			<td><ShimmerText /></td>
			<td><ShimmerText /></td>
		</tr>
	);
}

export default ShimmerLoad