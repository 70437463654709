import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import base64url from 'base64-url'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import jwt_decode from 'jwt-decode'
import { setProgressBar } from '../../../redux/Actions/ProgressAction';
import { TitleContent, Breadcrumbs } from '../component';
import { jwtKey, apiURL } from '../../../variable/Global';
import { Content, ShimmerLoad } from './Components'

class Nonton extends Component
{
	constructor(props){
		super(props);
		this.state = {
			pageTitle: "Nonton",
			pathname: "",
			id: "",
			data: []
		}
	}

	getData = () => {
		this.setState({ id: this.props.match.params.id})
		let id = base64url.decode(this.props.match.params.id)
		let enid = jwt.sign(id, jwtKey)
		let postData = new FormData()
		postData.append('id', enid)
		axios.post(apiURL +"nonton", postData)
			.then(res => {
				let data = jwt_decode(res.data.data)
				this.setState({ data: data })
				this.props.setProgressBar('')
			})
	}

	componentDidMount(){
		this.setState({
			pathname: this.props.history.location.pathname
		})
		this.getData()
		window.scrollTo(0, 0)
	}

	componentDidUpdate(){
		if(this.props.history.location.pathname !== this.state.pathname){
			this.setState({
				pathname: this.props.history.location.pathname
			})
			this.getData();
			window.scrollTo(0, 0)
		}
	}

	//**********************************************************************************************************************/
	//												Komponen
	//**********************************************************************************************************************/

	contentData = () => {
		if(this.state.data.length < 1){
			return (
				<ShimmerLoad />
			);
		} else {
			return (
				<Content
					id={this.state.id}
					imageCover={this.state.data.image_cover}
					title={this.state.data.title}
					otherTitle={this.state.data.other_title}
					tahun={this.state.data.tahun}
					episode={this.state.data.episode}
					rating={this.state.data.rating}
					vote={this.state.data.voting}
					allEpisodes={this.state.data.allEpisodes}
				/>
			);
		}
	}

	render(){
		return (
			<Fragment>
				<Helmet>
					<title>{this.state.pageTitle}</title>
					<meta name="robots" content="noindex" />
				</Helmet>
				<div className="content-wrapper">
					<div className="content-header">
						<div className="container-fluid">
							<div className="row mb-2">
								<TitleContent icon="fas fa-film" title={this.state.pageTitle} />
								<div className="col-sm-6">
									<ol className="breadcrumb float-sm-right">
										<Breadcrumbs title="Home" path="/alyka-admin" isActive="false" />
										<Breadcrumbs title="Anime" path="/alyka-admin/anime" isActive="false" />
										<Breadcrumbs title={this.state.pageTitle} path="/" isActive="true" />
									</ol>
								</div>
							</div>
						</div>
					</div>
					<section className="content">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									{this.contentData()}
								</div>
							</div>
						</div>
					</section>
				</div>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	setProgressBar: isOpen => {
		dispatch(setProgressBar(isOpen));
	}
});

export default connect(null, mapDispatchToProps)(Nonton)