import React, { Component, Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { withStyles, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { nullIsEmpty, apiURL } from '../../../../variable/Global'

const TextFieldColor = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#d695ab',
		},
		'& label.Mui-focused.Mui-error': {
			color: 'red !important',
		},
		// '& .MuiInput-underline:after': {
		// 	borderBottomColor: '#d695ab',
		// },
		'& .MuiOutlinedInput-root': {
			// '&:hover fieldset': {
			// 	borderColor: 'white',
			// },
			'&.Mui-focused fieldset': {
				borderColor: '#d695ab',
			},
			'&.Mui-focused.Mui-error fieldset': {
				borderColor: 'red !important',
			},
		},
		width: '100%'
	},
})(TextField);

const InputTemplate = (props) => {
	return (
		<Fragment>
			<TextFieldColor
				id={props.id}
				label={props.label}
				type={props.type}
				name={props.name}
				ref={props.refer}
				margin="normal"
				variant="outlined"
				defaultValue={props.value}
				noValidate
				placeholder={props.placeholder}
				InputProps={{
					readOnly: props.readOnly,
					shrink: props.shrink
				}}
				onChange={props.onChange}
				error={props.error > 0 ? true : false}
				autoComplete="off"
			/>
		</Fragment>
	)
}

const formValid = (formErrors, formServer) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formServer).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

const urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
				'(\\#[-a-z\\d_]*)?$','i');

const MySwal = withReactContent(Swal);
const Toast = MySwal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000
});
				
class FormServer extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			dataServer: ['blogger', 'vuplayer', 'photos', 'vufile', 'blogvunime', 'letsupload', 'uservideo', 'animeku', 'bloggerunc', 'googledrive', 'solidfiles', 'youtube'],
			dataQuality: ['360', '480', '720', '1080'],
			labelURLServer: "Data ID",
			showModal: false,
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
			formServer: {
				server: "blogger",
				quality: "360",
				urlserver: null,
				includeMin: ""
			},
			formErrors: {
				server: "",
				quality: "",
				urlserver: "",
			}
		}
	}

	showQuality = () => {
		const { formErrors } = this.state;
		return (
			<div className="col-md-12">
				<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
					<InputLabel id="demo-simple-select-outlined-label">Quality *</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						onChange={this.handleSelect}
						label="Quality *"
						name="quality"
						value={this.state.formServer.quality}
						error={formErrors.quality.length > 0 ? true : false}
					>
					{
						this.state.dataQuality.map((res, i) => {
							return <MenuItem value={res} key={i}>{res}</MenuItem>
						})
					}
					</Select>
				</FormControl>
				{formErrors.server.length > 0 && (
					<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.server}</span>
				)}
			</div>
		)
	}

	handleSelect = (e) => {
		e.preventDefault()
		let formErrors = { ...this.state.formErrors };
		let formServer = { ...this.state.formServer };
		const {name, value} = e.target;
		formErrors[name] = ""
		formServer[name] = value
		let labelURLServer = value === "vufile" || value === "letsupload" ? "Data ID" : "Url Server"
		this.setState({ formServer, formErrors, labelURLServer: labelURLServer })
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formServer = { ...this.state.formServer };

		formServer[name] = value === "" ? null : value

		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
		if(formServer.server !== 'vufile' && formServer.server !== 'letsupload'){
			if(!urlRegex.test(value)){
				formErrors[name] = "Harap isi dengan url yang valid"
			} else if(value === "" || value === null){
				formErrors[name] = "Harap di isi"
			} else {
				formErrors[name] = ""
			}
		} else {
			// if(format.test(value)){
			// 	formErrors[name] = "Harap isi dengan data id "+ formServer.server
			// } else if(value === "" || value === null){
			if(value === "" || value === null){
				formErrors[name] = "Harap di isi"
			} else {
				formErrors[name] = ""
			}
		}
	
		this.setState({ formErrors, formServer });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		let formServer = { ...this.state.formServer };

		formServer[name] = value === "" ? null : value

		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
		if(formServer.server !== 'vufile' && formServer.server !== 'letsupload'){
			// if(!urlRegex.test(value)){
			// 	formErrors[name] = "Harap isi dengan url yang valid"
			// } else if(value === "" || value === null){
			if(value === "" || value === null){
				formErrors[name] = "Harap di isi"
			} else {
				formErrors[name] = ""
			}
		} else {
			if(format.test(value)){
				formErrors[name] = "Harap isi dengan data id vufile"
			} else if(value === "" || value === null){
				formErrors[name] = "Harap di isi"
			} else {
				formErrors[name] = ""
			}
		}
		this.setState({ formErrors, formServer });
	}

	handleCheckbox = (e) => {
		let formServer = this.state.formServer
		const { name } = e.target
		var data;
		if (e.target.checked) {
			data = "1"
		} else {
			data = ""
		}
		formServer[name] = data
		this.setState({ formServer })
	}

	handleSubmit = (e) => {
		e.preventDefault()
		this.validate("urlserver", this.state.formServer.urlserver)
		if(formValid(this.state.formErrors, this.state.formServer)){
			this.setState({
				btnSaveDisabled: true,
				nameBtnSave: ButtonName(1)
			})
			let postData = new FormData()
			postData.append('epsId', this.props.epsId)
			postData.append('postId', this.props.postId)
			postData.append('page', this.props.page)
			postData.append('server', this.state.formServer.server)
			postData.append('quality', this.state.formServer.quality)
			postData.append('urlserver', this.state.formServer.urlserver)
			postData.append('includeMin', this.state.formServer.includeMin)
			axios.post(apiURL +"save-server", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleDataServerChange(res.data.data)
					}
					this.setState({
						btnSaveDisabled: false,
						nameBtnSave: ButtonName(0),
						formServer: {
							server: "blogger",
							quality: "360",
							urlserver: null,
						}
					})
				}).catch(err => {
					this.setState({
						btnSaveDisabled: false,
						nameBtnSave: ButtonName(0),
						formServer: {
							server: "blogger",
							quality: "360",
							urlserver: null,
						}
					})
					Toast.fire({
						icon: 'error',
						title: 'Data gagal disimpan'
					});
				})
		}
	}

	handleDataServerChange = (newDataServer) => {
		this.props.onChangeServer(newDataServer);
	}

	componentDidUpdate()
	{
		if(this.props.showModal !== this.state.showModal){
			this.setState({
				showModal: this.props.showModal,
			})
		}
	}

	render()
	{
		const { formErrors, formServer } = this.state;
		return (
			<Fragment>
				<Modal show={this.state.showModal} onHide={this.props.handleClose} size="md" backdrop="static">
					<Form onSubmit={this.handleSubmit} noValidate>
						<Modal.Header closeButton>
							<Modal.Title><i className="fas fa-film"></i> Episode: <span className="text-danger" style={{fontWeight: 'bold'}}>{this.props.episode}</span></Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="row">
								<div className="col-md-12">
									<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
										<InputLabel id="demo-simple-select-outlined-label">Server *</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											onChange={this.handleSelect}
											label="Server *"
											name="server"
											value={this.state.formServer.server}
											error={formErrors.server.length > 0 ? true : false}
										>
										{
											this.state.dataServer.map((res, i) => {
												return <MenuItem value={res} key={i}>{res}</MenuItem>
											})
										}
										</Select>
									</FormControl>
									{formErrors.server.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.server}</span>
									)}
								</div>
								{this.showQuality()}
								<div className="col-md-12">
									<div className="form-group">
										<InputTemplate
											type="text"
											id="urlserver"
											name="urlserver"
											label={`${this.state.labelURLServer} *`}
											value={nullIsEmpty(formServer.urlserver)}
											error={formErrors.urlserver.length > 0 ? true : false}
											onChange={this.handleChangeInput}
										/>
									</div>
									{formErrors.urlserver.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.urlserver}</span>
									)}
								</div>
							</div>
							{
								(formServer.quality === "720" || formServer.quality === "1080" && formServer.server === "vufile") && (
									<div className="col-md-12">
										<div className="form-group">
											<Form.Group>
												<Form.Check className="custom-control custom-checkbox" type="checkbox" id={`includeMin`}>
													<Form.Check.Input name="includeMin" type="checkbox" className="custom-control-input" value="1" onChange={this.handleCheckbox} checked={formServer.includeMin === '1' ? true : false} />
													<Form.Check.Label className="custom-control-label">Include Video Quality Rendah</Form.Check.Label>
												</Form.Check>
											</Form.Group>
										</div>
										<div style={{height: '20px'}}></div>
									</div>
								)
							}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="default" onClick={this.props.handleClose} disabled={this.state.btnSaveDisabled}>
								Close
							</Button>
							<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
								{this.state.nameBtnSave}
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</Fragment>
		)
	}
}

export default FormServer