import React, {Component, Fragment} from 'react'
import { Form } from 'react-bootstrap'
import { withStyles, TextField } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { nullIsEmpty, apiURL } from '../../../../variable/Global'

const TextFieldColor = withStyles({
	root: {
		'& label.Mui-focused': {
			color: '#d695ab',
		},
		'& label.Mui-focused.Mui-error': {
			color: 'red !important',
		},
		// '& .MuiInput-underline:after': {
		// 	borderBottomColor: '#d695ab',
		// },
		'& .MuiOutlinedInput-root': {
			// '&:hover fieldset': {
			// 	borderColor: 'white',
			// },
			'&.Mui-focused fieldset': {
				borderColor: '#d695ab',
			},
			'&.Mui-focused.Mui-error fieldset': {
				borderColor: 'red !important',
			},
		},
		width: '100%'
	},
})(TextField);

const InputTemplate = (props) => {
	return (
		<Fragment>
			<TextFieldColor
				id={props.id}
				label={props.label}
				type={props.type}
				name={props.name}
				ref={props.refer}
				margin="normal"
				variant="outlined"
				defaultValue={props.value}
				noValidate
				placeholder={props.placeholder}
				InputProps={{
					readOnly: props.readOnly,
					shrink: props.shrink
				}}
				onChange={props.onChange}
				error={props.error > 0 ? true : false}
				autoComplete="off"
			/>
		</Fragment>
	)
}

const formValid = (formErrors, formServer) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(formServer).forEach(val => {
		val === null && (valid = false);
	});
	
	return valid;
};

const ButtonName = (pos) => {
	switch(pos){
		case 1:
			return (
				<Fragment>
					<span className="fas fa-cog fa-spin"></span> Proses...
				</Fragment>
			);
		
		default:
			return 'Simpan';
	}
}

const date = new Date()
const dateTimeFormat = new Intl.DateTimeFormat('id', { year: 'numeric', day: '2-digit', month: '2-digit' }) 
const [{ value: day },,{ value: month },,{ value: year }] = dateTimeFormat.formatToParts(date) 

class FormEpisode extends Component
{
	constructor(props){
		super(props)
		this.state = {
			dataServer: ['blogger', 'photos', 'vuplayer', 'blogvunime', 'letsupload', 'uservideo', 'animeku', 'vufile', 'bloggerunc', 'googledrive', 'solidfiles', 'youtube'],
			dataQuality: ['360', '480', '720', '1080'],
			labelURLServer: "Data ID",
			showModal: false,
			moreEps: "",
			widthEps: "12",
			nameBtnSave: ButtonName(0),
			btnSaveDisabled: false,
			formServer: {
				episode: null,
				episodeLanjutan: "",
				option: "",
				tglUpdate: `${year}-${month}-${day}`,
				server: "blogger",
				quality: "360",
				urlserver: null,
				includeMin: ''
			},
			formErrors: {
				episode: "",
				episodeLanjutan: "",
				tglUpdate: "",
				server: "",
				quality: "",
				urlserver: "",
			}
		}
	}

	handleSelect = (e) => {
		e.preventDefault()
		let formErrors = { ...this.state.formErrors };
		let formServer = { ...this.state.formServer };
		const {name, value} = e.target;
		formErrors[name] = ""
		formServer[name] = value
		let labelURLServer = value === "vufile" || value === "letsupload" ? "Data ID" : "Url Server"
		this.setState({ formServer, formErrors, labelURLServer: labelURLServer })
	}

	showQuality = () => {
		const { formErrors } = this.state;
		return (
			<div className="col-md-12">
				<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
					<InputLabel id="demo-simple-select-outlined-label">Quality *</InputLabel>
					<Select
						labelId="demo-simple-select-outlined-label"
						id="demo-simple-select-outlined"
						onChange={this.handleSelect}
						label="Quality *"
						name="quality"
						value={this.state.formServer.quality}
						error={formErrors.quality.length > 0 ? true : false}
					>
					{
						this.state.dataQuality.map((res, i) => {
							return <MenuItem value={res} key={i}>{res}</MenuItem>
						})
					}
					</Select>
				</FormControl>
				{formErrors.server.length > 0 && (
					<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.server}</span>
				)}
			</div>
		)
	}

	handleEpsLanjutan = (e) => {
		var data, widthEps;
		let formErrors = { ...this.state.formErrors }
		let formServer = { ...this.state.formServer }
		if (e.target.checked) {
			data = "1"
			widthEps = "6"
			formErrors.episodeLanjutan = ""
			formServer.episodeLanjutan = null
		} else {
			data = ""
			widthEps = "12"
			formErrors.episodeLanjutan = ""
			formServer.episodeLanjutan = ""
		}
		this.setState({
			moreEps: data,
			widthEps: widthEps,
			formErrors,
			formServer
		})
	}

	handleCheckbox = (e) => {
		let formServer = this.state.formServer
		const { name } = e.target
		var data;
		if (e.target.checked) {
			data = "1"
		} else {
			data = ""
		}
		formServer[name] = data
		this.setState({ formServer })
	}

	contentEpisodeLanjutan = () => {
		if(this.state.moreEps === ""){
			return <Fragment></Fragment>
		} else {
			const { formErrors, formServer } = this.state;
			return (
				<div className="col-md-6">
					<div className="form-group">
						<InputTemplate
							type="number"
							id="episodeLanjutan"
							name="episodeLanjutan"
							label="Episode Lanjutan *"
							onChange={this.handleChangeInput}
							value={nullIsEmpty(formServer.episodeLanjutan)}
							error={formErrors.episodeLanjutan.length > 0 ? true : false}
						/>
					</div>
					{formErrors.episodeLanjutan.length > 0 && (
						<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.episodeLanjutan}</span>
					)}
				</div>
			)
		}
	}

	handleChangeInput = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		let formErrors = { ...this.state.formErrors };
		let formServer = { ...this.state.formServer };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
		formServer[name] = value === "" ? null : value
	
		this.setState({ formErrors, formServer });
	}

	validate = (name, value) => {
		let formErrors = { ...this.state.formErrors };
		formErrors[name] = value === null || value === "" ? "Harap di isi" : "";
	
		return formErrors[name]
	}

	handleDataServerChange = (newDataServer) => {
		this.props.onChangeServer(newDataServer);
	}

	handleSubmit = (e) => {
		e.preventDefault()
		let formErrors = { ...this.state.formErrors };
		let formServer = { ...this.state.formServer };

		formErrors.episode = this.validate("episode", formServer.episode)

		if(this.state.moreEps !== "") {
			formErrors.episodeLanjutan = this.validate("episodeLanjutan", formServer.episodeLanjutan)
		}

		formErrors.tglUpdate = this.validate("tglUpdate", formServer.tglUpdate)
		formErrors.urlserver = this.validate("urlserver", formServer.urlserver)

		this.setState({ formErrors })

		if(formValid(this.state.formErrors, this.state.formServer)){
			this.setState({
				btnSaveDisabled: true,
				nameBtnSave: ButtonName(1)
			})
			let postData = new FormData()
			postData.append('postId', this.props.postId)
			postData.append('episode', this.state.formServer.episode)
			postData.append('episodeLanjutan', this.state.formServer.episodeLanjutan)
			postData.append('option', this.state.formServer.option)
			postData.append('tglUpdate', this.state.formServer.tglUpdate)
			postData.append('server', this.state.formServer.server)
			postData.append('quality', this.state.formServer.quality)
			postData.append('urlserver', this.state.formServer.urlserver)
			postData.append('includeMin', this.state.formServer.includeMin)
			axios.post(apiURL +"save-episode", postData)
				.then(res => {
					if(res.data.result.toString() === "1"){
						this.handleDataServerChange(res.data.data)
					}
					this.setState({
						btnSaveDisabled: false,
						nameBtnSave: ButtonName(0),
						formServer: {
							episode: null,
							episodeLanjutan: "",
							option: "",
							tglUpdate: `${year}-${month}-${day}`,
							server: "blogger",
							quality: "360",
							urlserver: null,
						}
					})
				})
		}
	}

	componentDidUpdate(){
		if(this.props.showModal !== this.state.showModal){
			let episodeNow = this.props.currentEps === "" || this.props.currentEps === null ? 0 : this.props.currentEps
			let currentEps = parseInt(episodeNow) + 1
			let formServer = { ...this.state.formServer }
			formServer.episode = currentEps
			this.setState({
				showModal: this.props.showModal,
				formServer
			})
			if(this.props.showModal){
				this.setState({
					formErrors: {
						episode: "",
						episodeLanjutan: "",
						tglUpdate: "",
						server: "",
						quality: "",
						urlserver: "",
					}
				})
			}
		}
	}

	render(){
		const { formErrors, formServer } = this.state;
		return (
			<Fragment>
				<Modal show={this.state.showModal} onHide={this.props.handleClose} size="md" backdrop="static">
					<Form onSubmit={this.handleSubmit} noValidate>
						<Modal.Header closeButton>
							<Modal.Title><i className="fas fa-edit"></i> Episode</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="row">
								<div className={`col-md-${this.state.widthEps}`}>
									<div className="form-group">
										<InputTemplate
											type="number"
											id="episode"
											name="episode"
											label="Episode *"
											value={nullIsEmpty(formServer.episode)}
											onChange={this.handleChangeInput}
											error={formErrors.episode.length > 0 ? true : false}
										/>
									</div>
									{formErrors.episode.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.episode}</span>
									)}
								</div>
								{this.contentEpisodeLanjutan()}
								<div className="col-md-12">
									<div className="form-group">
										<InputTemplate
											type="text"
											id="option"
											name="option"
											label="Option"
											value={formServer.option}
											onChange={this.handleChangeInput}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<Form.Group>
											<Form.Check className="custom-control custom-checkbox" type="checkbox" id={`upload-drive`}>
												<Form.Check.Input name="upload-drive" type="checkbox" className="custom-control-input" checked={this.state.moreEps === "" ? false : true} value="1" onChange={this.handleEpsLanjutan.bind(this)} />
												<Form.Check.Label className="custom-control-label">Episode Lebih dari 1 dalam video</Form.Check.Label>
											</Form.Check>
										</Form.Group>
									</div>
									<div style={{height: '20px'}}></div>
								</div>
								<div className="col-md-12">
									<div className="form-group">
										<TextFieldColor
											id="date"
											label="Tanggal Update"
											type="date"
											variant="outlined"
											name="tglUpdate"
											defaultValue={nullIsEmpty(formServer.tglUpdate)}
											InputLabelProps={{
												shrink: true
											}}
											onChange={this.handleChangeInput}
											error={formErrors.tglUpdate.length > 0 ? true : false}
										/>
										{formErrors.tglUpdate.length > 0 && (
											<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.tglUpdate}</span>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<FormControl variant="outlined" style={{width: '100%', marginTop: '15px'}}>
										<InputLabel id="demo-simple-select-outlined-label">Server *</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											onChange={this.handleSelect}
											label="Server *"
											name="server"
											value={this.state.formServer.server}
											error={formErrors.server.length > 0 ? true : false}
										>
										{
											this.state.dataServer.map((res, i) => {
												return <MenuItem value={res} key={i}>{res}</MenuItem>
											})
										}
										</Select>
									</FormControl>
									{formErrors.server.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.server}</span>
									)}
								</div>
								{this.showQuality()}
								<div className="col-md-12">
									<div className="form-group">
										<InputTemplate
											type="text"
											id="urlserver"
											name="urlserver"
											label={`${this.state.labelURLServer} *`}
											value={nullIsEmpty(formServer.urlserver)}
											error={formErrors.urlserver.length > 0 ? true : false}
											onChange={this.handleChangeInput}
										/>
									</div>
									{formErrors.urlserver.length > 0 && (
										<span className="error invalid-feedback" style={{display: "block"}}>{formErrors.urlserver}</span>
									)}
								</div>
							</div>
							{
								((formServer.quality === "720" || formServer.quality === "1080") && formServer.server === "vufile") && (
									<div className="col-md-12">
										<div className="form-group">
											<Form.Group>
												<Form.Check className="custom-control custom-checkbox" type="checkbox" id={`includeMin`}>
													<Form.Check.Input name="includeMin" type="checkbox" className="custom-control-input" value="1" onChange={this.handleCheckbox} checked={formServer.includeMin === '1' ? true : false} />
													<Form.Check.Label className="custom-control-label">Include Video Quality Rendah</Form.Check.Label>
												</Form.Check>
											</Form.Group>
										</div>
										<div style={{height: '20px'}}></div>
									</div>
								)
							}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="default" onClick={this.props.handleClose} disabled={this.state.btnSaveDisabled}>
								Close
							</Button>
							<Button variant="dark" onClick={this.handleSubmit} disabled={this.state.btnSaveDisabled}>
								{this.state.nameBtnSave}
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</Fragment>
		)
	}
}

export default FormEpisode